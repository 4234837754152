import React from 'react';
import { IconContent, MenuItem, MenuList, Overlay, SideContainer } from './styled';
import { Icon } from 'ui/styled';
import useWindowSize from 'utils/useWindowSize';

export default function SideBarMe({ menuOptions, activeMenuItem, onMenuClick, isResponsiveActive, handleResponsive }) {
  const windowSize = useWindowSize();


  return (
    <>
      <Overlay active onClick={() => handleResponsive()} >

      </Overlay>
      <SideContainer>
        <IconContent>
          <Icon src={'/icons/close.svg'} onClick={() => handleResponsive()} />
        </IconContent>
        <MenuList>
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              active={option.label === activeMenuItem}
              onClick={() => onMenuClick(option.label)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </SideContainer>
    </>
  );
}