import styled from "styled-components";


export const ProductDescriptionContent = styled.div.attrs({

})`
  background-color: ${props => props.theme.palette.colors.white};
  padding-bottom: 24px;

  

`;

export const TitleContent = styled.div.attrs({

})`
  display: flex;
`;

export const ProductContent = styled.div.attrs({

})`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-wrap: wrap;
`;

export const Description = styled.div.attrs({

})`
  flex: 1;
  min-width: 300px;
`;

export const TextDescription = styled.div.attrs({

})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.palette.gray.gray800};

`;
export const Specifications = styled.div.attrs({

})`
  flex: 1;

`;

export const SpecificationItem = styled.div.attrs({

})`
  display: flex;
  padding: 12px 8px 12px 8px;
  gap: 8px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  margin-bottom: 2px;
`;

export const SpecificationLabel = styled.div.attrs({

})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: ${props => props.theme.palette.colors.blueStrong};

`;

export const SpecificationValue = styled.div.attrs({

})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
color: ${props => props.theme.palette.gray.gray800};
`;