import React from 'react';
import { StyledVideo, VideoContainer } from './styled';



export default function Video({ src }) {
  return (
    <VideoContainer>
      <StyledVideo src={src} controls />
    </VideoContainer>
  );
};

