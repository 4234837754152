export const products = [
  {
    id: 1,
    name: "Jim Beam White Kentucky Straight Bourbon Whisky",
    isVisible: true,
    description: "Jim Beam White Kentucky Straight Bourbon Whisky é um bourbon clássico.",
    descriptionShort: "Jim Beam White é um bourbon clássico.",
    keyWords: "whiskey, bourbon, jim beam, white",
    title: "Jim Beam White",
    isActive: true,
    metaTagDescription: "Compre Jim Beam White Kentucky Straight Bourbon Whisky, um bourbon clássico.",
    brand: {
      id: 1,
      name: "Jim Beam"
    }, category: {
      id: 1,
      name: "Whiskey"
    },
    sku: {
      id: 1,
      sku_code: "123456",
      quantity: 90,
      price: 123456,
      title: "Jim Beam White"
    },
    image: {
      id: 1,
      name: "jim-beam-white.png", alternativeText: null,
      caption: null,
      width: 1272,
      height: 716,
      formats: {
        large: {
          ext: ".png",
          url: "/uploads/large_jim_beam_white.png",
          hash: "large_jim_beam_white",
          mime: "image/png",
          name: "large_jim-beam-white.png",
          path: null,
          size: 508.3,
          width: 1000,
          height: 563
        },
        small: {
          ext: ".png",
          url: "/uploads/small_jim_beam_white.png",
          hash: "small_jim_beam_white",
          mime: "image/png",
          name: "small_jim-beam-white.png",
          path: null,
          size: 157.61,
          width: 500,
          height: 281
        },
        medium: {
          ext: ".png",
          url: "/uploads/medium_jim_beam_white.png",
          hash: "medium_jim_beam_white",
          mime: "image/png",
          name: "medium_jim-beam-white.png",
          path: null,
          size: 313.56,
          width: 750,
          height: 422
        },
        thumbnail: {
          ext: ".png",
          url: "/uploads/thumbnail_jim_beam_white.png",
          hash: "thumbnail_jim_beam_white",
          mime: "image/png",
          name: "thumbnail_jim-beam-white.png",
          path: null,
          size: 45.49,
          width: 245,
          height: 138
        }
      },
      hash: "jim_beam_white",
      ext: ".png",
      mime: "image/png",
      size: 237.18,
      url: "/uploads/jim_beam_white.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
    }
  },
  {
    id: 2,
    name: "Jim Beam Honey Kentucky Straight Bourbon Whisky",
    isVisible: true,
    description: "Jim Beam Honey Kentucky Straight Bourbon Whisky é um bourbon com infusão de mel.",
    descriptionShort: "Jim Beam Honey é um bourbon com infusão de mel.", keyWords: "whiskey, bourbon, jim beam, honey",
    title: "Jim Beam Honey",
    isActive: true,
    metaTagDescription: "Compre Jim Beam Honey Kentucky Straight Bourbon Whisky, um bourbon com infusão de mel.",
    showWithoutStock: true,
    brand: {
      id: 1,
      name: "Jim Beam"
    },
    category: {
      id: 1,
      name: "Whiskey"
    },
    sku: {
      id: 2,
      sku_code: "123457",
      quantity: 90,
      title: "Jim Beam Honey"
    },
    image: {
      id: 2,
      name: "jim-beam-honey.png",
      alternativeText: null, caption: null,
      width: 1272,
      height: 716,
      formats: {
        large: {
          ext: ".png",
          url: "/uploads/large_jim_beam_honey.png",
          hash: "large_jim_beam_honey",
          mime: "image/png", name: "large_jim-beam-honey.png",
          path: null,
          size: 508.3,
          width: 1000,
          height: 563
        },
        small: {
          ext: ".png",
          url: "/uploads/small_jim_beam_honey.png",
          hash: "small_jim_beam_honey",
          mime: "image/png",
          name: "small_jim-beam-honey.png",
          path: null,
          size: 157.61,
          width: 500,
          height: 281
        },
        medium: {
          ext: ".png",
          url: "/uploads/medium_jim_beam_honey.png",
          hash: "medium_jim_beam_honey",
          mime: "image/png",
          name: "medium_jim-beam-honey.png",
          path: null,
          size: 313.56,
          width: 750,
          height: 422
        },
        thumbnail: {
          ext: ".png",
          url: "/uploads/thumbnail_jim_beam_honey.png",
          hash: "thumbnail_jim_beam_honey",
          mime: "image/png",
          name: "thumbnail_jim-beam-honey.png",
          path: null,
          size: 45.49,
          width: 245,
          height: 138
        }
      },
      hash: "jim_beam_honey",
      ext: ".png",
      mime: "image/png",
      size: 237.18,
      url: "/uploads/jim_beam_honey.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
    }
  },
  {
    id: 3,
    name: 'Jim Beam Apple Kentucky Straight Bourbon Whisky',
    isVisible: true,
    description: 'Jim Beam Apple Kentucky Straight Bourbon Whisky é um bourbon com infusão de maçã.',
    descriptionShort: 'Jim Beam Apple é um bourbon com infusão de maçã.',
    keyWords: 'whiskey, bourbon, jim beam, apple',
    title: 'Jim Beam Apple',
    isActive: true,
    metaTagDescription: 'Compre Jim Beam Apple Kentucky Straight Bourbon Whisky, um bourbon com infusão de maçã.',
    showWithoutStock: true,
    brand: {
      id: 1,
      name: 'Jim Beam'
    },
    category: {
      id: 1,
      name: 'Whiskey'
    },
    sku: {
      id: 3,
      sku_code: '123458',
      quantity: 90,
      price: 123456,
      title: 'Jim Beam Apple'
    },
    image: {
      id: 3,
      name: 'jim-beam-apple.png',
      alternativeText: null,
      caption: null,
      width: 1272,
      height: 716,
      formats: {
        large: {
          ext: '.png',
          url: '/uploads/large_jim_beam_apple.png',
          hash: 'large_jim_beam_apple',
          mime: 'image/png',
          name: 'large_jim-beam-apple.png',
          path: null,
          size: 508.3,
          width: 1000,
          height: 563
        },
        small: {
          ext: '.png',
          url: '/uploads/small_jim_beam_apple.png',
          hash: 'small_jim_beam_apple',
          mime: 'image/png',
          name: 'small_jim-beam-apple.png',
          path: null,
          size: 157.61,
          width: 500,
          height: 281
        },
        medium: {
          ext: '.png',
          url: '/uploads/medium_jim_beam_apple.png',
          hash: 'medium_jim_beam_apple',
          mime: 'image/png',
          name: 'medium_jim-beam-apple.png',
          path: null,
          size: 313.56,
          width: 750,
          height: 422
        },
        thumbnail: {
          ext: '.png',
          url: '/uploads/thumbnail_jim_beam_apple.png',
          hash: 'thumbnail_jim_beam_apple',
          mime: 'image/png',
          name: 'thumbnail_jim-beam-apple.png',
          path: null,
          size: 45.49,
          width: 245,
          height: 138
        }
      },
      hash: 'jim_beam_apple',
      ext: '.png',
      mime: 'image/png',
      size: 237.18,
      url: '/uploads/jim_beam_apple.png',
      previewUrl: null,
      provider: 'local',
      provider_metadata: null,
      folderPath: '/'
    }
  },
  {
    id: 4,
    name: 'Jim Beam Black Kentucky Straight Bourbon Whisky',
    isVisible: true,
    description: 'Jim Beam Black Kentucky Straight Bourbon Whisky é um bourbon envelhecido por mais tempo.',
    descriptionShort: 'Jim Beam Black é um bourbon envelhecido por mais tempo.',
    keyWords: 'whiskey, bourbon, jim beam, black',
    title: 'Jim Beam Black',
    isActive: true,
    metaTagDescription: 'Compre Jim Beam Black Kentucky Straight Bourbon Whisky, um bourbon envelhecido por mais tempo.',
    showWithoutStock: true,
    createdAt: '2024-06-17T17:30:24.282Z',
    updatedAt: '2024-06-19T13:03:32.239Z',
    publishedAt: '2024-06-17T17:30:24.282Z',
    brand: {
      id: 1,
      name: 'Jim Beam',
      createdAt: '2024-06-17T17:30:35.116Z',
      updatedAt: '2024-06-18T19:25:52.163Z',
      publishedAt: '2024-06-18T12:39:10.387Z'
    },
    category: {
      id: 1,
      name: 'Whiskey',
      createdAt: '2024-06-17T17:30:52.658Z',
      updatedAt: '2024-06-18T19:25:52.158Z',
      publishedAt: '2024-06-18T12:39:16.758Z'
    },
    sku: {
      id: 4,
      sku_code: '123459',
      quantity: 90,
      price: 123456,
      createdAt: '2024-06-17T17:32:12.591Z',
      updatedAt: '2024-06-19T12:43:03.639Z',
      publishedAt: '2024-06-18T12:38:47.878Z',
      title: 'Jim Beam Black'
    },
    image: {
      id: 4,
      name: 'jim-beam-black.png',
      alternativeText: null,
      caption: null,
      width: 1272,
      height: 716,
      formats: {
        large: {
          ext: '.png',
          url: '/uploads/large_jim_beam_black.png',
          hash: 'large_jim_beam_black',
          mime: 'image/png',
          name: 'large_jim-beam-black.png',
          path: null,
          size: 508.3,
          width: 1000,
          height: 563
        },
        small: {
          ext: '.png',
          url: '/uploads/small_jim_beam_black.png',
          hash: 'small_jim_beam_black',
          mime: 'image/png',
          name: 'small_jim-beam-black.png',
          path: null,
          size: 157.61,
          width: 500,
          height: 281
        },
        medium: {
          ext: '.png',
          url: '/uploads/medium_jim_beam_black.png',
          hash: 'medium_jim_beam_black',
          mime: 'image/png',
          name: 'medium_jim-beam-black.png',
          path: null,
          size: 313.56,
          width: 750,
          height: 422
        },
        thumbnail: {
          ext: '.png',
          url: '/uploads/thumbnail_jim_beam_black.png',
          hash: 'thumbnail_jim_beam_black',
          mime: 'image/png',
          name: 'thumbnail_jim-beam-black.png',
          path: null,
          size: 45.49,
          width: 245,
          height: 138
        }
      },
      hash: 'jim_beam_black',
      ext: '.png',
      mime: 'image/png',
      size: 237.18,
      url: '/uploads/jim_beam_black.png',
      previewUrl: null,
      provider: 'local',
      provider_metadata: null,
      folderPath: '/'
    }
  },
  {
    id: 5,
    name: 'Jim Beam Rye Pre - prohibition style whiskey',
    isVisible: true,
    description: 'Jim Beam Rye Pre - prohibition style whiskey é um bourbon ao estilo pré-proibição.',
    descriptionShort: 'Jim Beam Rye é um bourbon ao estilo pré-proibição.',
    keyWords: 'whiskey, bourbon, jim beam, rye',
    title: 'Jim Beam Rye',
    isActive: true,
    metaTagDescription: 'Compre Jim Beam Rye Pre - prohibition style whiskey, um bourbon ao estilo pré-proibição.',
    showWithoutStock: true,
    createdAt: '2024-06-17T17:30:24.282Z',
    updatedAt: '2024-06-19T13:03:32.239Z',
    publishedAt: '2024-06-17T17:30:24.282Z',
    brand: {
      id: 1,
      name: 'Jim Beam',
      createdAt: '2024-06-17T17:30:35.116Z',
      updatedAt: '2024-06-18T19:25:52.163Z',
      publishedAt: '2024-06-18T12:39:10.387Z'
    },
    category: {
      id: 1,
      name: 'Whiskey',
      createdAt: '2024-06-17T17:30:52.658Z',
      updatedAt: '2024-06-18T19:25:52.158Z',
      publishedAt: '2024-06-18T12:39:16.758Z'
    },
    sku: {
      id: 5,
      sku_code: '123460',
      quantity: 90,
      price: 123456,
      createdAt: '2024-06-17T17:32:12.591Z',
      updatedAt: '2024-06-19T12:43:03.639Z',
      publishedAt: '2024-06-18T12:38:47.878Z',
      title: 'Jim Beam Rye'
    },
    image: {
      id: 5,
      name: 'jim-beam-rye.png',
      alternativeText: null,
      caption: null,
      width: 1272,
      height: 716,
      formats: {
        large: {
          ext: '.png',
          url: '/uploads/large_jim_beam_rye.png',
          hash: 'large_jim_beam_rye',
          mime: 'image/png',
          name: 'large_jim-beam-rye.png',
          path: null,
          size: 508.3,
          width: 1000,
          height: 563
        },
        small: {
          ext: '.png',
          url: '/uploads/small_jim_beam_rye.png',
          hash: 'small_jim_beam_rye',
          mime: 'image/png',
          name: 'small_jim-beam-rye.png',
          path: null,
          size: 157.61,
          width: 500,
          height: 281
        },
        medium: {
          ext: '.png',
          url: '/uploads/medium_jim_beam_rye.png',
          hash: 'medium_jim_beam_rye',
          mime: 'image/png',
          name: 'medium_jim-beam-rye.png',
          path: null,
          size: 313.56,
          width: 750,
          height: 422
        },
        thumbnail: {
          ext: '.png',
          url: '/uploads/thumbnail_jim_beam_rye.png',
          hash: 'thumbnail_jim_beam_rye',
          mime: 'image/png',
          name: 'thumbnail_jim-beam-rye.png',
          path: null,
          size: 45.49,
          width: 245,
          height: 138
        }
      },
      hash: 'jim_beam_rye',
      ext: '.png',
      mime: 'image/png',
      size: 237.18,
      url: '/uploads/jim_beam_rye.png',
      previewUrl: null,
      provider: 'local',
      provider_metadata: null,
      folderPath: '/'
    }
  }
];
export const cartItems = [
  {
    id: 1,
    product: {
      name: "Jim Beam White Kentucky - Whisky - 1L",
      image: "https://s3-alpha-sig.figma.com/img/fd2b/3287/35271429868d8f4b28d787785fa5ba73?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QT27dJM2f1TV3OEVkdstXo3MgzFFhSzKlfa25Ebv4Tl7yvH~Lgkiz-MQukcJyFevW8XPGjlbzGd26I6oGwFEY3I7PzFd9UfcAgE5zJCrTd9GHRrfy5oOhS7Nk0bTivOUZX0I8bVc~MRMooGTrM-M2sEaV2wh1HFVBnWhm0XwLZbsAX6C2ZBekZEm0uGyP1swJMbs1aS08wEq7EjxckBUpfxJZN6sy4llFdMCP8q262cLIaDveifnM93nIUwfFQlSBApgop9RNOiZuCbBMNqEg3W~9NCC6fGXU1V-rYv9aQ9DGl3Dc0SBkyZWVTRo8Osq6RQ7iqjd4xhek-xBxaJGEA__",
      seller: "Alpha comercio",
    },
    quantity: 1,
    price: {
      original: 12345.67,
      current: 12345.67,
    },
    shipping: {
      method: "Normal",
      duration: "até 12 dias",
      cost: 12.34,
    },
  },
  {
    id: 2,
    product: {
      name: "Jim Beam White Kentucky - Whisky - 1L",
      image: "https://s3-alpha-sig.figma.com/img/fd2b/3287/35271429868d8f4b28d787785fa5ba73?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QT27dJM2f1TV3OEVkdstXo3MgzFFhSzKlfa25Ebv4Tl7yvH~Lgkiz-MQukcJyFevW8XPGjlbzGd26I6oGwFEY3I7PzFd9UfcAgE5zJCrTd9GHRrfy5oOhS7Nk0bTivOUZX0I8bVc~MRMooGTrM-M2sEaV2wh1HFVBnWhm0XwLZbsAX6C2ZBekZEm0uGyP1swJMbs1aS08wEq7EjxckBUpfxJZN6sy4llFdMCP8q262cLIaDveifnM93nIUwfFQlSBApgop9RNOiZuCbBMNqEg3W~9NCC6fGXU1V-rYv9aQ9DGl3Dc0SBkyZWVTRo8Osq6RQ7iqjd4xhek-xBxaJGEA__",
      seller: "Alpha comercio",
    },
    quantity: 1,
    price: {
      original: 12345.67,
      current: 12345.67,
    },
    shipping: {
      method: "Normal",
      duration: "até 12 dias",
      cost: 12.34,
    },
  },
  {
    id: 3,
    product: {
      name: "Jim Beam White Kentucky - Whisky - 1L",
      image: "https://s3-alpha-sig.figma.com/img/fd2b/3287/35271429868d8f4b28d787785fa5ba73?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QT27dJM2f1TV3OEVkdstXo3MgzFFhSzKlfa25Ebv4Tl7yvH~Lgkiz-MQukcJyFevW8XPGjlbzGd26I6oGwFEY3I7PzFd9UfcAgE5zJCrTd9GHRrfy5oOhS7Nk0bTivOUZX0I8bVc~MRMooGTrM-M2sEaV2wh1HFVBnWhm0XwLZbsAX6C2ZBekZEm0uGyP1swJMbs1aS08wEq7EjxckBUpfxJZN6sy4llFdMCP8q262cLIaDveifnM93nIUwfFQlSBApgop9RNOiZuCbBMNqEg3W~9NCC6fGXU1V-rYv9aQ9DGl3Dc0SBkyZWVTRo8Osq6RQ7iqjd4xhek-xBxaJGEA__",
      seller: "Alpha comercio",
    },
    quantity: 1,
    price: {
      original: 12345.67,
      current: 12345.67,
    },
    shipping: {
      method: "Normal",
      duration: "até 12 dias",
      cost: 12.34,
    },
  },
];