import React from 'react'
import { DetailsTitle, MeContent, MeTitle } from 'ui/styled'
import FormCore from "../Form/Core";
import { ButtonContainer, TextAddress, TitleConetent } from './styled';
import Button from 'components/Form/Button';
import Check from 'components/Form/Check';

export default function MyAddress() {
  const addressFormItems = [
    {
      ref: 'tituloEndereco',
      type: 'text',
      placeholder: 'Título do endereço',
      half: true,
    },
    {
      ref: 'cep',
      type: 'text',
      placeholder: 'CEP',
      half: true,
    },
    {
      ref: 'logradouro',
      type: 'text',
      placeholder: 'Logradouro',
      full: true,
    },
    {
      ref: 'numero',
      type: 'text',
      placeholder: 'Número',
      half: true,
    },
    {
      ref: 'bairro',
      type: 'text',
      placeholder: 'Bairro',
      half: true,
    },
    {
      ref: 'cidade',
      type: 'text',
      placeholder: 'Cidade',
      half: true,
    },
    {
      ref: 'estado',
      type: 'text',
      placeholder: 'Estado',
      half: true,
    },
    {
      ref: 'complementos',
      type: 'text',
      placeholder: 'Complementos',
      full: true,
    }
  ];
  return (
    <>
      <MeContent>
        <TitleConetent>
          <DetailsTitle>Meus endereços</DetailsTitle>
          <TextAddress>+ Adicionar novo endereço</TextAddress>
        </TitleConetent>
        <Check
          success
          checked
          label='Casa'
        />
        <FormCore formItems={addressFormItems} />
        <ButtonContainer>
          <Button info>Cancelar</Button>
          <Button primary>Editar</Button>
        </ButtonContainer>
      </MeContent>

    </>
  )
}
