import React from 'react';
import { SummaryContainer, SummaryItem, SummaryLabel, SummaryTitle, SummaryTotal, SummaryValue, TotalLabel, TotalValue } from './styled';
import Button from 'components/Form/Button';
import { DetailsTitle } from 'ui/styled';
import { parseCurrency } from 'utils/parsers';


export default function PurchaseSummary({ productsTotal, qtd, servicesTotal, onCheckout, onContinueShopping, grandTotal, orderDetails }) {
  return (
    <SummaryContainer orderDetails={orderDetails}>
      {orderDetails ? <DetailsTitle border>Resumo da compra</DetailsTitle> : <SummaryTitle>Resumo da compra</SummaryTitle>}
      <SummaryItem orderDetails={orderDetails}>
        <SummaryLabel>{qtd} Produtos</SummaryLabel>
        <SummaryValue>{parseCurrency(productsTotal)}</SummaryValue>
      </SummaryItem>
      {/* <SummaryItem orderDetails={orderDetails}>
        <SummaryLabel>Frete / serviços</SummaryLabel>
        <SummaryValue>R$ {servicesTotal}</SummaryValue>
      </SummaryItem> */}
      <SummaryTotal>
        <TotalLabel>Total</TotalLabel>
        <TotalValue>{parseCurrency(grandTotal ? grandTotal : productsTotal + servicesTotal)}</TotalValue>
      </SummaryTotal>
      {orderDetails ? null : <>
        { !productsTotal ? null : <Button noRadius nospace primary onClick={onCheckout}>Finalizar compra</Button> }
        <Button outline primary noRadius onClick={onContinueShopping}>Continuar comprando</Button>
      </>
      }
    </SummaryContainer>
  );
};

