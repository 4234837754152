import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/products`, params);
}

export const Read = async () => {
    return await GET(`/products?populate=*`);
}

export const ReadOne = async (id) => {
    return await GET(`/products/${id}?populate=*`);
}

export const Update = async (params, id) => {
    return await PUT(`/products/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/products/${ id }`);
} 
 