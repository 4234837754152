import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    ForgotContent,
    ForgotContainer
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoForgotPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import { ButtonContainer, Icon, Title } from "ui/styled";

export default function Forgot() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)

    const valid = (verbose = false) => {

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        return true
    }

    const action = async () => {
        // if (!valid(true)) { return; }
        // setLoading(true)

        // const result = await DoForgotPassword({ email: formValue('email')?.replace(/ /g, '') })

        // setLoading(false)
        // if (result && !exposeStrapiError(result)) {
        next()
        // }
    }

    const completNext = () => {
        toast.success('Instruções para recuperar senha foram enviadas ao seu email');
        navigate('login')
    }

    const steps = useMemo(() => [
        {
            title: 'Esqueceu sua senha?',
            text: 'Digite seu e-mail cadastrado para recuperar sua senha',
            label: 'Voltar',
            labelNext: 'Continuar',
        },
        {
            title: 'Verifique seu email',
            text: 'Acesse o link de recuperação que foi enviado no seu email cadastrado',
            label: 'Fechar',
        },

    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }
    return (
        <>
            <ContainerUnauthenticated forgot>
                <ForgotContainer>

                    <ForgotContent>
                        {!(step === 0) ? null :
                            <>
                                <Title noSpace orange>{steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <Input noRadius placeholder="Email" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} />
                                <FormSpacer />
                                <ButtonContainer>
                                    <Button nospace link primary start onClick={() => back()}>
                                        <Icon src="/icons/arrow-left.svg" />
                                        {steps?.[step]?.label}
                                    </Button>
                                    <Button nospace primary between loading={loading} onClick={action} >
                                        {steps?.[step]?.labelNext}
                                        <Icon src="/icons/arrow-right.svg" />
                                    </Button>
                                </ButtonContainer>
                            </>
                        }
                        {
                            !(step === 1) ? null : <>
                                <Title noSpace orange>{steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <FormSpacer />
                                <ButtonContainer>
                                    <Button width='50%' primary nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                                </ButtonContainer>
                            </>
                        }
                    </ForgotContent>
                </ForgotContainer>
            </ContainerUnauthenticated>
        </>
    );
}