import React from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,

    DashboardHeader,
    ButtonContent,
    OptionTitle,
    MenuContentItems,
    Title,
    IconList

} from "./styled";

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { Icon } from "ui/styled";
import useWindowSize from "utils/useWindowSize";
import { brandOptions, categories, contactOptions, icons, menuItems } from "utils/options";

export default function DashboardSide({ opened, setOpened }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const windowSize = useWindowSize();

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            setOpened(false)
        }
    }

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }
    const handleChange = (brandName) => {
        const formattedBrandName = brandName.replace(/\s+/g, '-');
        navigate(`brand/${formattedBrandName}`)
    };
    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer onClick={verifyClose}>
                        <DashboardMenu>
                            <DashboardMenuHeader >
                                <Icon src="/icons/side-logo.svg" />
                                <DashboardMenuHeaderIcon src="/icons/close.svg" onClick={() => setOpened(false)} />
                            </DashboardMenuHeader>
                            {/* <DashboardHeader>
                                <Icon src="/images/frete.svg" />
                                <ButtonContent>
                                    <Button big between primary noRadius='1px' onClick={() => navigate('dashboard/Me')}>
                                        Minha conta
                                        <Icon src="/icons/profile.svg" />
                                    </Button>
                                    <Button big between primary noRadius='1px' >
                                        Suporte
                                        <Icon src="/icons/support.svg" />
                                    </Button>
                                </ButtonContent>

                            </DashboardHeader> */}

                            <DashboardMenuContent>
                                <MenuContentItems>
                                    <Title>Menu</Title>
                                    {
                                        menuItems.map((item, index) => (
                                            <DashboardMenuOption onClick={() => navigate(item.link)}>
                                                <OptionTitle>{item.label}</OptionTitle>
                                                <Icon src="/icons/direction-right.svg" />
                                            </DashboardMenuOption>

                                        ))
                                    }
                                </MenuContentItems>
                                {/* {
                                    windowSize.width > 744 ? null :
                                        <MenuContentItems marginTop>
                                            <Title>Nossas marcas</Title>
                                            {
                                                brandOptions.map((item, index) => (
                                                    <DashboardMenuOption onClick={() => handleChange(item.title)}>
                                                        <OptionTitle>
                                                            {item.title}</OptionTitle>
                                                        <Icon src="/icons/direction-right.svg" />
                                                    </DashboardMenuOption>

                                                ))
                                            }
                                        </MenuContentItems>
                                } */}
                                {/* <MenuContentItems marginTop>
                                    <Title>Categorias</Title>
                                    {
                                        categories.map((item, index) => (
                                            <DashboardMenuOption onClick={() => navigate(item.link)}>
                                                <OptionTitle>{item.label}</OptionTitle>
                                                <Icon src="/icons/direction-right.svg" />
                                            </DashboardMenuOption>

                                        ))
                                    }
                                </MenuContentItems> */}
                                <MenuContentItems marginTop>
                                    <Title>Termos</Title>
                                    <DashboardMenuOption onClick={() => navigate('terms')}>
                                        <OptionTitle>Termos</OptionTitle>
                                    </DashboardMenuOption>
                                </MenuContentItems>

                            </DashboardMenuContent>

                            <DashboardMenuFooter>
                                <MenuContentItems white marginTop >
                                    <Title centred>Confira nossas redes sociais</Title>
                                    <IconList>
                                        {icons.map((icon, index) => (
                                            <Icon key={index} src={icon.icon} />
                                        ))}
                                    </IconList>
                                </MenuContentItems>

                            </DashboardMenuFooter>

                        </DashboardMenu>
                    </DashboardMenuContainer >
            }
        </>
    );
}