import React, { useState } from "react";

import Footer from "components/Footer";
import useWindowSize from "utils/useWindowSize";


import { TabsContainer, Tab, FormContent, CartContent, CartTitle } from './styled';


// const tabs = ['Sacola', 'Endereço', 'Pagamento', 'Finializado'];
const tabs = ['Sacola'];

export default function CheckoutContent({ title, tabType, children, PaymentSuccess }) {
    const windowSize = useWindowSize();
    return (
        <>
            <CartContent>
                <FormContent>
                    <TabsContainer>
                        {tabs.map(tab => (
                            <Tab
                                key={tab}
                                isActive={tab === tabType}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </TabsContainer>
                    {title ? <CartTitle>{title}</CartTitle> : null}
                    {children}
                </FormContent>
            </CartContent>
            <Footer noFooter light />
        </>
    );
}