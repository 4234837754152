import styled from "styled-components";

export const SideContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
    @media (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  // Added shadow
  }
`;
export const Overlay = styled.div.attrs({
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  // Semi-transparent overlay
  z-index: 998;
  display: none;

  @media (max-width: 600px) {
    display: ${props => (props.active ? 'block' : 'none')}; // Show overlay when active
  }
`;
export const SideOptionsContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  border-radius: 4px;
  padding: 0px 16px 16px 16px;
  ${props => props.top && 'margin-top: 16px;'}
  width: 288px;
`;

export const FormSpace = styled.div.attrs({
})`
  border: 0.5px solid ${props => props.theme.palette.gray.gray100};
  margin-top: 16px;
`;
export const SideTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  display: flex;
  justify-content: space-between;

`;

export const SideOptions = styled.div.attrs({
})`

  display: flex;
  align-items: center;

`;

export const Icon = styled.img.attrs({
})`
  cursor: pointer;
  margin-top: 16px;
  margin-right: 16px;
  right: 0;
  position: absolute;
`;