import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 

    margin: 50px 0 10px;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
    ${props => props.center ? 'text-align: center;' : ''}

`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 32px;
    margin-bottom: 16px;
    ${props => props.noBorder ? '' : `
        border-top: 0.5px solid ${props.theme.palette.gray.gray600};
        `};
`;

export const RegisterCall = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.grey};
    display: flex;
`;
export const FormContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    padding: 40px 40px 40px 40px;
    flex: 1;
`;


export const RegisterContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Text = styled.div.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

`;

export const Link = styled.a.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
`;
export const CheckContainer = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.backgroundgrey};
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
`;

export const SuccessContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    padding: 100px 40px 100px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;



