import styled from "styled-components";

export const PaymentContent = styled.div.attrs({
})`
  max-width: 800px;
  flex: 1;
  background-color: ${props => props.theme.palette.colors.white};
  margin-top: 16px;
  padding: 16px;

`
export const PaymentBody = styled.div.attrs({
})`
  flex: 1;
  background-color: ${props => props.theme.palette.colors.white};
  margin-left: 32px;
`

export const MethodsTitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-top: 8px;
  width: 100%;


`
export const CheckContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.background};
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 8px;

`
export const InfoMessage = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
  ${props => props.border ? `
  padding-top: 8px;
  border-top: 1px solid ${props.theme.palette.gray.gray100};
    `: ``};
`

export const List = styled.ol.attrs({
})`
  list-style-type: decimal;
  font-size: 16px;
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  padding-top: 8px;
  & > li {
  }
`;

export const ListItem = styled.li.attrs({
})`
  line-height: 1.6;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin-left: -8px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
`;