import React from "react";
import PropTypes from 'prop-types';

import { ColorButton, Load } from "./styled";
import { ThemedComponent } from "ui/theme";

export const Button = ({ children, big, loading, primary, secondary, outline, link, nospace, centred, onClick, width, small, info, orange, blue, noRadius, full, between, start }) => {

  return (
    <>
      <ThemedComponent>
        <ColorButton variant={link ? "text" : outline ? "outlined" : "contained"} color={secondary ? 'secondary' : primary ? 'primary' : info ? 'info' : orange ? 'orange' : blue ? 'blue' : 'white'} blue={blue} nospace={nospace} centred={centred} big={big} onClick={onClick} width={width} small={small} noRadius={noRadius} start={start} full={full} between={between}>
          {
            loading ? <Load primary={primary} secondary={secondary} outline={outline} /> : <>
              {children}
            </>
          }
        </ColorButton>
      </ThemedComponent>
    </>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  nospace: PropTypes.bool,
  centred: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: undefined,
  primary: false,
  secondary: false,
  outline: false,
  link: false,
  nospace: false,
  centred: false,
  loading: false,
  onClick: undefined,
};

export default Button;