import styled from 'styled-components'

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.black};
    margin-bottom: 12px;
    ${props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;
export const FormContent = styled.div.attrs({
})` 
    width: '100%',

`;

export const DashboardText = styled.div.attrs({
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${props => props.theme.palette.colors.black};
    ${props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({
    width: '100%',
    height: 420
})`             
`;
export const Container = styled.div.attrs({
})` 
    background-color: ${props => props.theme.palette.brandsBackground.main};
`;
export const BrandsContainer = styled.div.attrs({
})` 
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.palette.brandsBackground.main};
  padding: 56px 96px;
  max-width: 1512px;
  margin: 0 auto;
  gap: 78px;
  overflow: auto;
  @media (max-width: 600px) {
  padding: 36px 16px;
}
`;
export const ImgContainer = styled.div.attrs({
})` 
  display: flex;
  max-width: 231px;
  min-width: 70px;
`;
export const Img = styled.img.attrs({
})` 
  width: 100%;
`;