import styled from 'styled-components';

const ScrollContainer = styled.div`
  max-height: ${(props) => props.maxHeight || '100%'};
  overflow-y: auto;
  margin-right: 10px;
  margin-top: 16px;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.trackColor || props.theme.palette.gray.gray100};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.thumbColor || props.theme.palette.colors.grey};
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.thumbHoverColor || props.theme.palette.colors.black};
    cursor: pointer;
  }
`;

const Scroll = ({ children, maxHeight, trackColor, thumbColor, thumbHoverColor }) => {
  return (
    <ScrollContainer
      maxHeight={maxHeight}
      trackColor={trackColor}
      thumbColor={thumbColor}
      thumbHoverColor={thumbHoverColor}
    >
      {children}
    </ScrollContainer>
  );
};

export default Scroll;