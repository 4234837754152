import { blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#030811',
      contrastText: '#fff',
    },
    secondary: {
      main: '#06346b',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#e86c30',
    },
    error: {
      main: '#dd4952',
    },
    brandsBackground: {
      main: '#282D36',
    },
    footerBackground: {
      main: '#1A1A1A',

    },
    sugestionsGradient: {
      main: '#282D36',
      primary: '#484D57',
      secondary: '#6D727B',
    },
    paymentBackground: {
      primary: '#333333',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#D4D7DE',
      contrastText: '#000',
    },
    sellers: {
      main: '#DDDDDD',
    },
    success: {
      main: '#06C167',
    },
    gray: {
      gray50: '#F3F3F3',
      gray40: '#E1E1E1',
      gray100: '#E8E8E8',
      gray300: 'rgba(187, 187, 187, 0.5)',
      gray600: 'rgba(114, 114, 114, 0.6)',
      gray800: 'rgba(75, 75, 75, 0.8)',

    },
    gradient: {
      main: '#868686',
      primary: '#BBBBBB',
      secondary: '#DDDDDD',
      tertiary: '#E8E8E8',
    },
    jimbeam: {
      main: '#AB2328',
      secondary: '#EDBE58',
      contrastTitle: '#fff',
      contrastText: '#000',
    },
    makersmark: {
      main: '#2D2926',
      secondary: '#d6a852',
      contrastTitle: '#AF292E',
      contrastText: '#000',
    },
    roku: {
      main: '#000',
      secondary: '#d6a852',
      contrastTitle: '#000',
      contrastText: '#000',
    },
    thehouseofsuntory: {
      main: '#d6a852',
      secondary: '#0a4312',
      contrastTitle: '#d6a852',
      contrastText: '#d6a852',
    },
    laphroaig: {
      main: '#0a4312',
      secondary: '#0a4312',
      contrastTitle: 'White',
      contrastText: 'White',
    },
    macallan: {
      main: '#d6a852',
      secondary: '#282d53',
      contrastTitle: '#d6a852',
      contrastText: '#d6a852',
    },
    red: {
      main: '#FF002E',
      contrastText: '#fff',
    },
    blue: {
      main: '#F5F5F5',
      contrastText: '#494A5E',
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      grey: '#4C4C4C',
      backgroundgrey: '#F5F5F5',
      lightgrey: '#A4A4A4',
      shadow: 'rgba(0,0,0,.16)',
      lightshadow: 'rgba(112,112,112,.06)',
      background: '#F8F8F8',
      bluefull: '#494A5E',
      blue100: '#E9E9EB',
      blue: '#25284C',
      blueStrong: '#1B1D36',
      cream: '#F4F1EF',

    }
  },
});  