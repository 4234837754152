import styled from 'styled-components';

export const GalleryContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  max-height: 632px;
  margin: 0 auto;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Thumbnails = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-height: 120px;
  @media (min-width: 769px) {
    flex-direction: column;
    max-height: 632px;
    margin-right: 16px;
  }
`;

export const Thumbnail = styled.img.attrs({
})`
  width: 80px;
  height: 80px;
  cursor: pointer;
  border: ${props => props.selected ? '2px solid black' : '2px solid transparent'};
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  width: 107px;
  height: 107px;

`;

export const MainImage = styled.img.attrs({
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const MainImageContent = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  width: 672px;
  height: 632px;
  @media (max-width: 768px) {
    width: 100%;
    height: 420px;
    margin-top: 16px;
  }
  
 
`;