import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/newsletters`, params);
}

export const Read = async () => {
    return await GET(`/newsletters?populate=*`);
}

export const ReadOne = async (id) => {
    return await GET(`/newsletters/${id}?populate=*`);
}

export const Update = async (params, id) => {
    return await PUT(`/newsletters/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/newsletters/${ id }`);
} 
 