import React from 'react'
import { ProductListContent, FormContent, ProductContent, TitleContent } from './styled'
import ProductCard from 'components/ProductCard'
import { Icon, Title } from 'ui/styled';
import Button from 'components/Form/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function ProductList({ title, products }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  return !products?.length ? null : (
    <>
      <ProductListContent>
        <FormContent>
          <TitleContent>
            <Title>{title}</Title>
            <Button info noRadius nospace width={'129px'} onClick={() => navigate('products')}>
              Ver todos
            </Button>
          </TitleContent>
          <ProductContent>
            {
              products.map((drink, index) => (
                <ProductCard key={index} {...drink} />
              ))
            }
          </ProductContent>
        </FormContent>
      </ProductListContent>
    </>
  )
}
