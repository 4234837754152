import styled from "styled-components";

export const MyRequestsContent = styled.div.attrs({
})`
    background-color: ${props => props.theme.palette.white.main};
    padding: 16px;
    max-width: 960px;
    flex: 1;

`;

export const MyRequestsTitle = styled.div.attrs({
})`

    font-size: 24px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const Container = styled.div.attrs({
})`
  padding: 20px;
  background-color:${props => props.theme.palette.colors.backgroundgrey};;
`;

export const OrderCard = styled.div.attrs({
})`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
`;

export const OrderDetails = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
`;

export const OrderStatus = styled.div.attrs({
})`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;

`;

export const OrderInfo = styled.div.attrs({
})`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;

`;

export const OrderAmount = styled.p`

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;

  @media (max-width: 600px) {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

`;