import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})` 
    max-width: 1512px;  
    padding: 32px 96px;
    margin: 0 auto;
    min-height: 90vh;
    @media (max-width: 768px) {
      padding: 32px 16px;
    }
`;


export const CartContent = styled.div.attrs({
})`    
    background-color: ${props => props.theme.palette.colors.white};
`;

export const TabsContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 40px;
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray300};
  @media (max-width: 768px) {
    gap: 16px;
    width: 100%;
  }
`;

export const Tab = styled.div.attrs({
})`
  padding: 8px 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  border-bottom: 4px solid ${props => (props.isActive ? props.theme.palette.primary.main : 'transparent')};
  color: ${props => (props.isActive ? props.theme.palette.primary.main : props.theme.palette.colors.lightgrey)};
  margin-bottom: -2px;
`;

export const CartTitle = styled.div.attrs({
})`
    font-family: DM Serif Display;
    font-size: 32px;
    font-weight: 400;
    line-height: 56px;
    text-align: left;
    margin-top: 24px;
    color: ${props => props.theme.palette.primary.main};
`;
