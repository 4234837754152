export const drinks = []

let a = [
  {
    id: 1,
    name: "Jim Beam White Kentucky Straight Bourbon Whisky - 1L",
    image: "/images/jim-beam-white.svg",
    discountedPrice: 1234.56,
    paymentConditions: "12x de R$1.234,56 s/ juros",
  },
  {
    id: 2,
    name: "Jim Beam Honey Kentucky Straight Bourbon Whisky - 1L",
    image: "/images/jim-beam-honey.svg",
    originalPrice: 12345.67,
    discountedPrice: 1234.56,
    paymentConditions: "12x de R$1.234,56 s/ juros",
    discount: "50% OFF"
  },
  {
    id: 3,
    name: "Jim Beam Apple Kentucky Straight Bourbon Whisky - 1L",
    image: "/images/jim-beam-apple.svg",
    originalPrice: 12345.67,
    discountedPrice: 1234.56,
    paymentConditions: "12x de R$1.234,56 s/ juros",
    discount: "50% OFF"
  },
  {
    id: 4,
    name: "Jim Beam Black Kentucky Straight Bourbon Whisky - 1 L",
    image: "/images/jim-beam-black.svg",
    originalPrice: 12345.67,
    discountedPrice: 1234.56,
    paymentConditions: "12x de R$1.234,56 s/ juros",
  },
  {
    id: 5,
    name: "Jim Beam Rye Pre - prohibition style whiskey - 1 L",
    image: "/images/jim-beam-rye.svg",
    originalPrice: 12345.67,
    discountedPrice: 1234.56,
    paymentConditions: "12x de R$1.234,56 s/ juros",
    discount: "50% OFF"
  }
];

