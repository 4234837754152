import React, { useState } from 'react'
import { FormContent, ProductTitle, ProductinfoContent, Text } from './styled'

import ShippingDeadline from 'components/ShippingDeadline'
import SellerDetails from 'components/SellerDetails'

export default function ProductInfo({ price, id, title, product}) {



  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (qt) => {
    setSelectedOption(qt);
  };
  const sellers = [
    {
      name: "Alpha comercio",
      price: price,
      discount: "0%",
      originalPrice: price,
      // paymentConditions: "ou 12x de R$1.234,56 sem juros",
      shippingMethod: " ",
      // shippingDetails: {
      //   type: "Sedex",
      //   duration: "até 12 dias",
      //   cost: 12.34
      // }
    },
    // {
    //   name: "Betha comercio",
    //   price: 12345.67,
    //   paymentConditions: "ou 12x de R$1.234,56 sem juros",
    //   shippingMethod: "Normal – Compre agora e receba",
    //   shippingDetails: {
    //     type: "Sedex",
    //     duration: "até 12 dias",
    //     cost: 12.34
    //   }
    // },
  ];
  return (
    <>
      <FormContent>
        <ProductinfoContent noSpace>
          <ProductTitle>{title}</ProductTitle>
          {/* <ShippingDeadline /> */}
          {/* <Text>Escolha um dos fornecedores abaixo e compre</Text> */}
          <Text> </Text>
          <SellerDetails sellers={sellers} product={product} />
        </ProductinfoContent>
      </FormContent>

    </>
  )
}
