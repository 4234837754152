import styled from "styled-components";
import Carousel from 'react-bootstrap/Carousel';

export const Img = styled.img.attrs({
})`
  cursor: pointer;
  width: 100%;
`;

export const CustomCarousel = styled(Carousel)`
  .carousel-indicators {
    display: flex;
    justify-content: center;
  }

  .carousel-indicators button {
    width: 8px;
    height: 8px;
    background-color: grey;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
  }

  .carousel-indicators .active {
    background-color: ${props => props.theme.palette.colors.white};
  }
  .carousel-control-next {

    width: 5%;
  }
    .carousel-control-prev {

    width: 5%;
  }

  @media (max-width: 600px) {
     .carousel-control-next  {
      display: none;
    }
     .carousel-control-prev  {
      display: none;
    }
  } 
`;

export const Icon = styled.img.attrs((props) => ({
}))``;