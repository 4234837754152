import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormCore from "../../../components/Form/Core";


import {

    FormText,
    FormSpacer,

    RegisterContainer,
    FormContent,
    Text,
    Link,
    CheckContainer,
    SuccessContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoRegister } from "services/authentication";
import { exposeStrapiError } from "utils";
import { ButtonContainer, Content, Icon, Title } from "ui/styled";
import Check from "components/Form/Check";
import { optionsStates } from "utils/options";
import PasswordValidation from "components/Form/PasswordValidation";

export default function Register() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)

    const valid = (verbose = false) => {

        if (!formValue('name') || !formValue('name').length) {
            if (verbose) { toast.error('Preencha o campo: Nome'); }
            return false;
        }

        if (!formValue('email') || !formValue('email').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Senha'); }
            return false;
        }

        return true
    }

    const action = async () => {
        // if (!valid(true)) { return; }
        // setLoading(true)

        // const result = await DoForgotPassword({ email: formValue('email')?.replace(/ /g, '') })

        // setLoading(false)
        // if (result && !exposeStrapiError(result)) {
        next()
        // }
    }


    const completeLogin = () => {
        toast.success('Conta criada com sucesso');
        navigate('login')
    }

    const steps = useMemo(() => [
        {
            title: 'Dados pessoais',
            text: 'Informe seu endereço',
            label: 'Cancelar',
            labelNext: 'Continuar',
        },
        {
            title: 'Endereço',
            text: 'Informe seu endereço',
            label: 'Voltar',
            labelNext: 'Continuar',
        },
        {
            title: 'Senha',
            text: 'Crie agora sua senha de segurança dentro dos parâmetros de segurança',
            label: 'Voltar',
            labelNext: 'Concluir cadastro',
        },
        {
            title: 'Cadastro finalizado com sucesso',
            text: 'Obrigado por se cadastrar. Boas compras!',
            label: 'Fechar',
        },
    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }
    const personalInfoFormItems = [
        {
            ref: 'nome',
            type: 'text',
            placeholder: 'Nome',
            half: true,
        },
        {
            ref: 'sobrenome',
            type: 'text',
            placeholder: 'Sobrenome',
            half: true,
        },
        {
            ref: 'cpf',
            type: 'text',
            placeholder: 'CPF',
            full: true,
        },
        {
            ref: 'email',
            type: 'text',
            placeholder: 'Email',
            half: true,
        },
        {
            ref: 'telefone',
            type: 'text',
            placeholder: 'DDD + Celular',
            half: true,
        }
    ];
    const addressFormItems = [
        {
            ref: 'cep',
            type: 'text',
            placeholder: 'CEP',
            quarter: true,
        },
        {
            ref: 'logradouro',
            type: 'text',
            placeholder: 'Logradouro',
            half: true,
        },
        {
            ref: 'numero',
            type: 'text',
            placeholder: 'Número',
            quarter: true,
        },
        {
            ref: 'bairro',
            type: 'text',
            placeholder: 'Bairro',
            half: true,
        },
        {
            ref: 'complementos',
            type: 'text',
            placeholder: 'Complementos',
            half: true,
        },
        {
            ref: 'cidade',
            type: 'text',
            placeholder: 'Cidade',
            oneThird: true,
        },
        {
            ref: 'estado',
            type: 'text',
            options: optionsStates,
            placeholder: 'Estado',
            quarter: true,
        }
    ];
    return (
        <>
            <ContainerUnauthenticated>
                <RegisterContainer>
                    {
                        (step === 3) ? <>
                            <SuccessContent>
                                <Icon src="/icons/success.svg" />
                                <Content>
                                    <Title top center noSpace>{steps?.[step]?.title}</Title>
                                    <FormText center>{steps?.[step]?.text}</FormText>
                                    <FormSpacer />
                                    <ButtonContainer>
                                        <Button width='50%' primary nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                                    </ButtonContainer>
                                </Content>
                            </SuccessContent>
                        </> :

                            <FormContent>
                                {!(step === 0) ? null :
                                    <>
                                        <Title noSpace >{steps?.[step]?.title}</Title>
                                        <FormText>{steps?.[step]?.text}</FormText>

                                        <FormCore formItems={personalInfoFormItems} />
                                        <CheckContainer>
                                            <Check success onChange={() => { }}
                                                label={
                                                    <Text>
                                                        Ao selecionar e continuar, você concorda com nossos <Link>Termos de serviço</Link> e <Link>Política de privacidade</Link>
                                                    </Text>
                                                }
                                            />
                                        </CheckContainer>
                                        <CheckContainer>
                                            <Check success onChange={() => { }}
                                                label={
                                                    <Text>
                                                        Quero receber ofertas e novidades por e-mail
                                                    </Text>
                                                }
                                            />
                                        </CheckContainer>
                                        <FormSpacer />
                                        <ButtonContainer>
                                            <Button nospace link primary start onClick={() => back()}>
                                                <Icon src="/icons/arrow-left.svg" />
                                                {steps?.[step]?.label}
                                            </Button>
                                            <Button nospace primary between loading={loading} onClick={action} >
                                                {steps?.[step]?.labelNext}
                                                <Icon src="/icons/arrow-right.svg" />
                                            </Button>
                                        </ButtonContainer>
                                    </>}
                                {
                                    !(step === 1) ? null : <>
                                        <Title noSpace >{steps?.[step]?.title}</Title>
                                        <FormText>{steps?.[step]?.text}</FormText>
                                        <FormCore formItems={addressFormItems} />
                                        <FormSpacer />
                                        <ButtonContainer>
                                            <Button nospace link primary start onClick={() => back()}>
                                                <Icon src="/icons/arrow-left.svg" />
                                                {steps?.[step]?.label}
                                            </Button>
                                            <Button nospace primary between loading={loading} onClick={action} >
                                                {steps?.[step]?.labelNext}
                                                <Icon src="/icons/arrow-right.svg" />
                                            </Button>
                                        </ButtonContainer>
                                    </>
                                }
                                {!(step === 2) ? null : <>

                                    <Content>
                                        <Title noSpace>{steps?.[step]?.title}</Title>
                                        <FormText>{steps?.[step]?.text}</FormText>
                                        <Input id={'password'} placeholder="Digite uma senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                        <FormSpacer noBorder />
                                        <Input id={'cpassword'} placeholder="Repita a senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                                        <FormSpacer noBorder />
                                        <PasswordValidation password={formValue('password')} />
                                        <FormSpacer />
                                        <ButtonContainer>
                                            <Button nospace link primary start onClick={() => back()}>
                                                <Icon src="/icons/arrow-left.svg" />
                                                {steps?.[step]?.label}
                                            </Button>
                                            <Button nospace primary between loading={loading} onClick={next} >
                                                {steps?.[step]?.labelNext}
                                                <Icon src="/icons/arrow-right.svg" />
                                            </Button>
                                        </ButtonContainer>
                                    </Content>
                                </>
                                }
                            </FormContent>
                    }

                </RegisterContainer>
            </ContainerUnauthenticated>
        </>
    );
}