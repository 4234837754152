import styled from "styled-components";

export const AddressFormContainer = styled.div.attrs({
})`
  max-width: 676px;
  background-color: #fff;
  padding: 40px;
  flex: 1;
`

export const AddressFormTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;

`
export const TitleContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
export const FormSpacer = styled.div.attrs({
})`           
    margin: 32px 0;
    border-top: 0.5px solid rgba(114, 114, 114, 0.6);
`;
