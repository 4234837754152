import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})` 
    max-width: 1512px;  
    padding: 32px 96px;
    margin: 0 auto;
`;


export const CartContent = styled.div.attrs({
})`    
    background-color: ${props => props.theme.palette.colors.white};
`;

export const TabsContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 40px;
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray300};
`;

export const Tab = styled.div.attrs({
})`
  padding: 8px 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  border-bottom: 4px solid ${props => (props.isActive ? props.theme.palette.orange.main : 'transparent')};
  color: ${props => (props.isActive ? props.theme.palette.orange.main : props.theme.palette.colors.lightgrey)};
  margin-bottom: -2px;
`;

export const CartTitle = styled.div.attrs({
})`
    font-family: Uniwars;
    font-size: 30px;
    font-style: italic;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 2px;
    text-align: left;
    margin-top: 24px;
    color: ${props => props.theme.palette.orange.main};
`;
export const InputContente = styled.div.attrs({
})`   
    display: flex;
    align-items: end;
    width: 100%;
    gap: 4px;
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    padding: 16px;
    margin-top: 24px;
    @media (max-width: 768px) {
        padding: 0;
    }
  `;

export const CartProductsContent = styled.div.attrs({
})`    
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    padding: 16px;
    max-width: 800px;
    @media (max-width: 768px) {
    width: 100%;
}

`;

export const Title = styled.div.attrs({
})`
    font-family: DM Serif Display;
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;

    color: ${props => props.theme.palette.primary.main};
`;

export const CartProductQuantity = styled.div.attrs({
})`
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

    margin: 3px 0 0 10px;

    color: ${props => props.theme.palette.primary.main};
`;

export const ContentTitle = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }
`;
export const CartProductSummary = styled.div.attrs({
})`
    display: flex;
    margin-top: 32px;
    gap: 24px;
    flex-wrap: wrap;
`;