
import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})` 
   width: 496px;
   background-color: ${props => props.theme.palette.colors.backgroundgrey};
   padding: 16px 16px 16px 16px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
}
`;

export const ProductinfoContent = styled.div.attrs({
})` 
  margin-top: 14px;
  ${props => props.noSpace && 'margin-top: 0px;'}
`;

export const ProductTitle = styled.div.attrs({
})` 
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 56px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
`;

export const Text = styled.div.attrs({
})` 
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 24px;
`;