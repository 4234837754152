import React, { useContext, useState } from 'react';
import { OriginalPrice, Discount, PaymentConditions, Price, SellerContainer, SellerNameContent, ShippingDetails, ShippingMethod, DiscountContent, SellerPriceContainer, ShippingMethodContainer } from './styled';
import Button from 'components/Form/Button';
import Check from 'components/Form/Check';
import { Content } from 'ui/styled';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import { parseCurrency } from 'utils/parsers';



const SellerDetails = ({ sellers, product }) => {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const [active, setActive] = useState(false);

  const { cart, setCart } = useContext(CoreContext)

  const buy = () => {
    const qs = `sc=1&sku=${ product?.ref_id }&qty=1&seller=1` 
    window.location.href = (`https://www.menimport.com.br/checkout/cart/add?${qs}`)
  }

  const addToCart = () => {
    setCart([ ...cart, { ...product, quantity:1} ])
    navigate('cart')
  }

  return (
    <>
      {sellers.map((seller, index) => (
        <SellerContainer key={index}>
          {/* <SellerNameContent>
            <Check label={`Vendido e entregue por ${seller.name}`} checked={active === seller.name} onChange={() => setActive(seller.name)} />
          </SellerNameContent> */}
          <SellerPriceContainer>

            <DiscountContent>
              {seller.originalPrice && <OriginalPrice>{parseCurrency(seller.originalPrice)}</OriginalPrice>}
              {seller.discount && <Discount>{seller.discount} OFF</Discount>}
            </DiscountContent>
            <Content>
              <Price >{parseCurrency(seller.price)}</Price>
              <PaymentConditions>{seller.paymentConditions}</PaymentConditions>
            </Content>
          </SellerPriceContainer>
          <ShippingMethodContainer>

            <ShippingMethod>{seller.shippingMethod}</ShippingMethod>
            {
              !seller?.shippingDetails ? null :
              <ShippingDetails>
                {seller.shippingDetails.type} ({seller.shippingDetails.duration}) por: R$ {seller.shippingDetails.cost}
              </ShippingDetails>
            }
          </ShippingMethodContainer>
          <Button outline primary onClick={addToCart}>Adicionar ao carrinho</Button>
          <Button primary onClick={buy}>Comprar</Button>
        </SellerContainer>
      ))}
    </>
  );
};

export default SellerDetails;