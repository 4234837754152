import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    PasswordContent,
    PasswordContainer,
    SuccessContent
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import { DoResetPassword } from "services/authentication";
import { exposeStrapiError } from "utils";
import { ButtonContainer, Content, Icon, Title } from "ui/styled";
import PasswordValidation from "components/Form/PasswordValidation";
import ContainerAuthenticated from "containers/Authenticated";

export default function ToRecoverPassword() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const params = new URLSearchParams(window.location.search)
    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const [step, setStep] = useState(0)

    const valid = (verbose = false) => {

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Nova senha'); }
            return false;
        }

        if (!formValue('cpassword') || !formValue('cpassword').length) {
            if (verbose) { toast.error('Preencha o campo: Confirmar nova senha'); }
            return false;
        }

        if (formValue('password') !== formValue('cpassword')) {
            if (verbose) { toast.error('Nova senha e confirmação da nova senha não são iguais'); }
            return false;
        }

        return true
    }

    const action = async () => {
        // if (!valid(true)) { return; }
        // setLoading(true)

        // const result = await DoResetPassword({
        //     code: params.get('code'),
        //     password: formValue('password'),
        //     passwordConfirmation: formValue('cpassword')
        // })

        // setLoading(false)
        // if (result && !exposeStrapiError(result)) {
        //     completNext()
        // }
        next()
    }

    const completNext = () => {
        toast.success('Senha criada com sucesso');
        navigate('login')
    }
    const steps = useMemo(() => [
        {
            title: 'Senha',
            text: 'Digite uma nova senha para sua conta',
            label: 'Voltar',
            labelNext: 'Concluir cadastro',
        },
        {
            title: 'Nova senha criada com sucesso',
            text: 'Agora você já pode fazer seu login com sua nova senha',
            label: 'Fechar',
        },

    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }

    return (
        <>
            <ContainerAuthenticated >
                <PasswordContainer>
                    {!(step === 0) ? null :
                        <>
                            <PasswordContent>
                                <Title orange noSpace>{steps?.[step]?.title}</Title>
                                <FormText>{steps?.[step]?.text}</FormText>
                                <Input id={'password'} placeholder="Digite uma senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                                <FormSpacer noBorder />
                                <Input id={'cpassword'} placeholder="Repita a senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />
                                <FormSpacer noBorder />
                                <PasswordValidation password={formValue('password')} />
                                <FormSpacer />
                                <ButtonContainer>
                                    <Button nospace link primary start onClick={() => back()}>
                                        <Icon src="/icons/arrow-left.svg" />
                                        {steps?.[step]?.label}
                                    </Button>
                                    <Button nospace primary between loading={loading} onClick={action} >
                                        {steps?.[step]?.labelNext}
                                        <Icon src="/icons/arrow-right.svg" />
                                    </Button>
                                </ButtonContainer>
                            </PasswordContent>
                        </>
                    }
                    {
                        !(step === 1) ? null : <>
                            <SuccessContent>

                                <Icon src="/icons/success.svg" />
                                <Content>
                                    <Title top center noSpace orange>{steps?.[step]?.title}</Title>
                                    <FormText center>{steps?.[step]?.text}</FormText>
                                    <FormSpacer />
                                    <ButtonContainer>
                                        <Button width='50%' primary nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                                    </ButtonContainer>
                                </Content>
                            </SuccessContent>
                        </>
                    }
                </PasswordContainer>
            </ContainerAuthenticated>
        </>
    );
}