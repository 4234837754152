import styled from 'styled-components'



export const DashboardHeaderContainer = styled.div.attrs({
})`           
    background: ${props => props.theme.palette.colors.white};
    display: flex;
    align-items: center;
    width: 100%;
`;

export const DashboardHeaderAction = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.white};

`;
export const FormContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 1512px;  
    margin: 0 auto;
    padding: 16px 96px;
    flex: 1;
    display: flex;
       @media(max-width: 600px){
        padding: 16px 16px;
        justify-content: space-between;
    }
`;
export const HeaderContent = styled.div.attrs({
})`           
    margin-left: 80px; 
    display: flex;
    align-items: center;
    width: 100%;
    gap: 32px;
`;


export const DashboardHeaderActionIcon = styled.img.attrs({
})`           
    margin-right: 40px;
    @media(max-width: 600px){
        margin-right: 0;
    }
`;

export const DashboardMenuContainer = styled.div.attrs({
})`           
    height: 60px;
    width: 100%;
    padding: 0 96px;
    display: flex;
    align-items: center;
    background: ${props => props.theme.palette.colors.white};
`;

export const ContentIcon = styled.div.attrs({
})`         
    display: flex;
    align-items: center;
    ${props => props.center ? `justify-content: center;`
        : ` ${props.start ? ` justify-content: flex-start;`
            : ` ${props.end ? ` justify-content: flex-end;`
                : `${props.between ? ` justify-content: space-between;
                ` : ``
                }`
            }`
        }`
    }`;

export const CartContent = styled.div.attrs({
})`         
    display: flex;
    position: relative;
    justify-content: center;
    cursor: pointer;
`;
export const CartNumber = styled.div.attrs({
})` 
    margin-left: 3px;
    margin-top: 3px;
    position: absolute;
    border-radius: 8px;
    background: ${props => props.theme.palette.red.main};
    color: ${props => props.theme.palette.red.contrastText};
    min-width: 16px;
    height: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    padding: 0 3px;
`;
export const HeaderLinkContain = styled.div.attrs({
})`           
    font-size: 14px; 
    color: ${props => props.theme.palette.colors.grey};
    display: flex;
    justify-content: center;
`;

export const HeaderLink = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.grey};
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

export const InpuntContainer = styled.div.attrs({
})`
    width: 320px;
    margin-right: 8px;
    @media(max-width: 600px){
        width: 100%;
        padding: 0 16px;
        margin-right: 0;
        margin: 5px 0;
    }
             
`;
export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    gap: 24px;
    align-items: center;
`;
