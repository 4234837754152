import CountdownTimer from 'components/CountdownTimer'
import React from 'react'
import { List, ListItem, PaymentPixContainer, PaymentPixQRCodeContent, PixInfo, PixTitle, QRCodeWrapper } from './styled'
import Button from 'components/Form/Button'

export default function PaymentPix() {
  const paymentInstructions = [
    "Acesse o app ou site do seu banco;",
    "Escolha pagar com Pix;",
    "Escaneie o QR Code ou copie o código do pix;",
    "Confirme o pagamento"
  ];
  return (
    <>
      <PaymentPixContainer>
        <PaymentPixQRCodeContent>
          <QRCodeWrapper>
            {/* replace with qrcode */}
            <img src="/icons/qrcode.svg" alt="QR Code" />
          </QRCodeWrapper>
          <Button nospace width={'208px'} noRadius primary>Copiar código Pix</Button>
        </PaymentPixQRCodeContent>
        <PixInfo>
          <PixTitle>Pague com Pix no app ou site do seu banco utilizando o QR code ou copiando o código!</PixTitle>

          <CountdownTimer maxTime={25} />

          {
            <List>
              {paymentInstructions.map((instruction, index) => (
                <ListItem key={index}>{instruction}</ListItem>
              ))}
            </List>
          }
        </PixInfo>
      </PaymentPixContainer>
    </>
  )
}
