import React, { useContext, useEffect, useState } from "react";
import {
    ContainerMe,
    IconContent,
    MeContent,
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import { CoreContext } from "context/CoreContext";
import 'moment/locale/pt-br';
import Button from "components/Form/Button";
import { parseStrapiImage } from "utils";
import { ReadMe, RemoveMe, UpdateMe } from "services/me";
import { toast } from 'react-toastify';
import { DoLogout } from "services/authentication";
import { useHistory } from "react-router-dom";
import { FormContent, Icon, MeTitle } from "ui/styled";
import SideBarMe from "components/SideBarMe";
import MyRequests from "components/MyRequests";
import MyData from "components/MyData";
import MyAddress from "components/MyAddress";
import Footer from "components/Footer";
import Support from "components/Support";
import useWindowSize from "utils/useWindowSize";

export default function DashboardMe() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user, setUser } = useContext(CoreContext)

    const [preview, setPreview] = useState(user?.image?.url ? parseStrapiImage(user?.image?.url) : null)
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [activeMenuItem, setActiveMenuItem] = useState("Meus dados");
    const [isResponsiveActive, setIsResponsiveActive] = useState(false);
    const windowSize = useWindowSize();

    const exit = async () => {
        await DoLogout()
        navigate('login');
    }

    const removeAccount = async () => {
        setLoading(true)
        await RemoveMe();
        toast.error('Conta excluida com sucesso')
        exit();
        setLoading(false)
    }



    const handleMenuClick = (label) => {
        if (label === "Sair") {
            exit();
        }
        setActiveMenuItem(label);
        handleResponsive();
    }

    const menuOptions = [
        { label: "Meus dados" },
        { label: "Meu endereços" },
        { label: "Meus pedidos" },
        { label: "Suporte" },
        { label: "Sair" }
    ];
    const handleResponsive = () => {
        setIsResponsiveActive(!isResponsiveActive);
    };
    return (
        <>
            <ContainerAuthenticated>
                <MeContent>
                    <FormContent>
                        <MeTitle>{activeMenuItem === "Suporte" ? 'Atendimento ao cliente' : 'Minha conta'}
                            <IconContent>
                                <Icon src={'/icons/menu.svg'} onClick={() => handleResponsive()} />
                            </IconContent>
                        </MeTitle>
                        <ContainerMe>
                            {
                                (windowSize.width > 600 || isResponsiveActive) &&
                                <SideBarMe
                                    menuOptions={menuOptions}
                                    activeMenuItem={activeMenuItem}
                                    onMenuClick={handleMenuClick}
                                    isResponsiveActive={isResponsiveActive}
                                    handleResponsive={handleResponsive}
                                />
                            }
                            {
                                activeMenuItem === "Meus dados" ?
                                    <MyData />
                                    : null
                            }
                            {
                                activeMenuItem === "Meu endereços" ?
                                    <MyAddress />
                                    : null

                            }
                            {
                                activeMenuItem === "Meus pedidos" ?
                                    <MyRequests />
                                    : null
                            }
                            {
                                activeMenuItem === "Suporte" ?
                                    <Support />
                                    : null

                            }
                        </ContainerMe>
                    </FormContent>
                </MeContent>
                <Footer noFooter />
            </ContainerAuthenticated>
        </>
    );
}