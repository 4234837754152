import React, { useEffect, useMemo, useState } from "react";
import {
    BrandsContainer,
    Container,
    FormContent,
    Img,
    ImgContainer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import CarouselApp from "components/carousel";
import SuggestionsCard from "components/SuggestionsCard";
import Footer from "components/Footer";
import ProductList from "components/ProductList";
import { InpuntContainer } from "components/Dashboard/Header/styled";
import Input from "components/Form/Input";
import useWindowSize from "utils/useWindowSize";
import { drinks } from "utils/drinks";
import SuggestionNewsLetter from "components/SuggestionNewsLetter";
import PromotionCategory from "components/PromotionCategory";
import PaymentMethods from "components/PaymentMethods";
import AgeVerificationForm from "components/AgeVerificationForm";
import { Load, LoadCenter } from "ui/styled";
import { Read } from "services/home";
import { normalizeStrapiRegister, parseStrapiImage } from "utils";

export default function DashboardHome() {

    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const windowSize = useWindowSize();

    const [home, setHome] = useState(null);
    const [ageActive, setAgeActive] = useState(true);

    const imgs = useMemo(() => {
        return (home?.banners||[])?.map( m => ({
            alt: m?.name,
            url: parseStrapiImage(m?.url),
        }))
    }, [home])

    const cardData = []
    let b = [
        { title: "Oportunidades" },
        { title: "Frete grátis" },
        { title: "Dia dos Pais", color: "primary" },
        { title: "Kits", color: "primary" },
        { title: "Mês Bourbon", color: "secondary" },
    ];

    const brands =  [
        { id: 1, src: '/icons/jim-beam.svg', alt: 'jim-beam' },
        { id: 2, src: '/icons/makers-mark.svg', alt: 'makers-mark' },
        { id: 3, src: '/icons/roku-gin.svg', alt: 'roku-gin' },
        { id: 4, src: '/icons/house-suntory.svg', alt: 'house-suntory' },
        { id: 5, src: '/icons/laphroaig.svg', alt: 'laphroaig' },
        { id: 6, src: '/icons/macallan.svg', alt: 'macallan' },
    ]

    const handleAgeActive = () => {
        setAgeActive(false);
    }

    const init = async () => {
        if(!loading){
            setLoading(true)
            const result = await Read()
            const normalResult = normalizeStrapiRegister(result)
            console.log( "HOME PAGE", normalResult )
            setHome(normalResult)
            setLoading(false)
        }
    }

    useEffect(() => {init()},[])

    return (
        <>
            <ContainerAuthenticated>
                {/* Renderiza o formulário de verificação de idade apenas se ageActive for true */}
                {ageActive && <AgeVerificationForm handleAgeActive={handleAgeActive} />}
                <FormContent>
                    {windowSize.width < 600 ? (
                        <InpuntContainer>
                            <Input white noClear placeholder="Buscar" holder type="text" search value={formValue('search')} noRadius onChange={e => changeForm(e.target.value, 'search')} />
                        </InpuntContainer>
                    ) : null}

                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }
                    
                    <CarouselApp imgs={imgs} />
                    <Container>
                        <BrandsContainer>
                            {brands.map(brand => (
                                <ImgContainer key={brand.id}>
                                    <Img src={brand.src} alt={brand.alt} />
                                </ImgContainer>
                            ))}
                        </BrandsContainer>
                    </Container>

                    <SuggestionsCard cardData={cardData} />

                    <ProductList title="Os mais cobiçados" products={(home?.top_rateds||[])} />
                    <SuggestionNewsLetter />
                    <PromotionCategory />
                    <ProductList title="Os mais vendidos" products={(home?.top_sellers||[])} />

                    <PaymentMethods />
                    <Footer />

                </FormContent>
            </ContainerAuthenticated>
        </>
    );
}