import React from 'react'
import { AddressFormContainer, AddressFormTitle, FormSpacer, TitleContainer } from './styled';
import { ButtonContainer, Icon } from 'ui/styled';
import FormCore from "../Form/Core";
import Button from 'components/Form/Button';
import { optionsStates } from 'utils/options';

export default function AddressForm({ setNewAddress }) {
  const addressFormItems = [
    {
      ref: 'tituloEndereco',
      type: 'text',
      placeholder: 'Título do endereço',
      value: 'Trabalho',
      half: true,
    },
    {
      ref: 'cep',
      type: 'text',
      placeholder: 'CEP',
      value: '01234-567',
      half: true,
    },
    {
      ref: 'logradouro',
      type: 'text',
      placeholder: 'Logradouro',
      value: 'Rua dos testes',
      full: true,
    },
    {
      ref: 'numero',
      type: 'text',
      placeholder: 'Número',
      value: '12345',
      half: true,
    },
    {
      ref: 'bairro',
      type: 'text',
      placeholder: 'Bairro',
      value: 'Rua dos testes',
      half: true,
    },
    {
      ref: 'cidade',
      type: 'text',
      placeholder: 'Cidade',
      value: 'São Paulo',
      half: true,
    },
    {
      ref: 'estado',
      type: 'select',
      placeholder: 'Estado',
      options: optionsStates,
      value: 'São Paulo',
      half: true,
    },
    {
      ref: 'complementos',
      type: 'text',
      placeholder: 'Complementos',
      value: 'Torre A - Escada B - Sala 5',
      full: true,
    },
  ];
  return (
    <>
      <AddressFormContainer>
        <TitleContainer>
          <AddressFormTitle>Adicione um novo endereço</AddressFormTitle>
          <Icon style={{ cursor: 'pointer' }} onClick={() => setNewAddress(false)} src="/icons/close.svg" />
        </TitleContainer>
        <FormCore formItems={addressFormItems} />
        <FormSpacer />
        <ButtonContainer space>
          <Button nospace noRadius info onClick={() => setNewAddress(false)}>
            Cancelar
          </Button>
          <Button nospace primary noRadius onClick={() => { }} >
            Adicionar
          </Button>
        </ButtonContainer>
      </AddressFormContainer>
    </>
  )
}
