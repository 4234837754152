import styled from "styled-components";

export const BrandsContent = styled.div.attrs({
})`  
   background-color: ${props => props.theme.palette.colors.white};
   

   `;
export const FormContent = styled.div.attrs({
})`  
  padding: 46px 96px;
  max-width: 1512px;
  margin: 0 auto;
  @media (max-width: 600px) {
    padding: 16px;
  }

`;

export const BrandsContainer = styled.div.attrs({
})` 
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const ImgContainer = styled.div.attrs({
})` 
  display: flex;
  background-color: ${props => props.theme.palette.colors.blueStrong};
  width: 408px;

`;
export const Img = styled.img.attrs({
})` 
  width: 100%;
  height: 220px;
`;