import styled from 'styled-components'

export const CheckoutContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  padding: 16px;
  margin-top: 16px;
  ${props => props.address && `
    flex: 1;
    `};
`
export const AddressItems = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray100};

`
export const AddressContent = styled.div.attrs({
})`
  display: flex;
`
export const Title = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;

  background-color: ${props => props.payment ? props.theme.palette.colors.backgroundgrey : props.theme.palette.colors.white};
  max-width: 632px;
  padding: 8px 16px 0px 16px;

`

export const CheckoutForm = styled.div.attrs({
})`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`

export const CheckContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  max-width: 632px;
  padding: 8px 16px;
  margin-top: 24px;
  flex: 1;
`
export const Label = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
`
export const AddressContainer = styled.div.attrs({
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 200;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;