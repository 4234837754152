import React from 'react'
import { Container, MyRequestsTitle, OrderAmount, OrderCard, OrderDetails, OrderInfo, OrderStatus } from './styled'
import { Icon, MeContent } from 'ui/styled';
import { useHistory } from "react-router-dom";


export default function MyRequests() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const orders = [
    {
      status: "Processando pagamento",
      orderDate: "01/02/24",
      orderNumber: "123456789",
      amount: "R$ 12.345,67"
    },
    {
      status: "Processando pagamento",
      orderDate: "01/02/24",
      orderNumber: "123456789",
      amount: "R$ 12.345,67"
    },
    {
      status: "Processando pagamento",
      orderDate: "01/02/24",
      orderNumber: "123456789",
      amount: "R$ 12.345,67"
    },
    {
      status: "Processando pagamento",
      orderDate: "01/02/24",
      orderNumber: "123456789",
      amount: "R$ 12.345,67"
    }
  ];
  const handleOrderClick = (orderNumber) => {
    navigate(`order/${orderNumber}`);
  }
  return (
    <>
      <MeContent>
        <MyRequestsTitle>Meus pedidos</MyRequestsTitle>
        <Container>
          {orders.map((order, index) => (
            <OrderCard key={index} onClick={() => handleOrderClick(order.orderNumber)}>
              <OrderDetails>
                <OrderStatus>{order.status}</OrderStatus>
                <OrderInfo>Data do pedido: {order.orderDate}</OrderInfo>
                <OrderInfo>Número do pedido: {order.orderNumber}</OrderInfo>
              </OrderDetails>
              <OrderAmount>{order.amount}
                <Icon src="/icons/direction-right.svg" />
              </OrderAmount>
            </OrderCard>
          ))}
        </Container>
      </MeContent>
    </>
  )
}
