import React, { useContext, useState } from 'react';
import { CheckboxContainer, Container, Disclaimer, FormRow, Label, Overlay, Subtitle, Title } from './styled';
import Check from 'components/Form/Check';
import Select from 'components/Form/Select';
import { ButtonContainer, Icon } from 'ui/styled';
import { dateOptions } from 'utils/options';
import Button from 'components/Form/Button';
import useWindowSize from 'utils/useWindowSize';
import { CoreContext } from 'context/CoreContext';

export default function AgeVerificationForm({ handleAgeActive }) {
  
  const windowSize = useWindowSize();
  const [rememberMe, setRememberMe] = useState(false);
  
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const { agree, setAgree } = useContext(CoreContext)

  const save = () => {

    if(!!rememberMe){ 

      setAgree({ day, month, year, rememberMe })

    }
    
    
    handleAgeActive()
  }

  return agree ? null : (
    <>
      <Overlay active>
        <Container>
          <Icon src="/icons/side-logo.svg" alt="Logo" />
          <Title>Bem Vindo ao site da Prime Spirits Club</Title>
          <Subtitle>Para visitar o site, você deve ter mais de 18 anos.<br />Beba com responsabilidade.</Subtitle>
          <FormRow>
            <Select options={dateOptions.days} placeholder="Dia"
              label
              lightgrey
              value={day} onChange={setDay}
              width={windowSize.width < 600 ? '300px' : '182.67px'}
              noRadius
            />
            <Select width={windowSize.width < 600 ? '300px' : '182.67px'}
              value={month} onChange={setMonth}
              lightgrey options={dateOptions.months} placeholder="Mês" label noRadius />
            <Select width={windowSize.width < 600 ? '300px' : '182.67px'} lightgrey noRadius
              value={year} onChange={setYear}
              options={dateOptions.years} placeholder="Ano" label />
          </FormRow>
          <CheckboxContainer>
            <Check
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              label='Lembre-se de mim, por favor'
            />
          </CheckboxContainer>
          <Disclaimer>
            Beam Suntory incentiva o consumo responsável. O álcool deve ser consumido com moderação. Ao entrar neste site, você concorda com nossos Termos e Condições, Política de Privacidade e Política de Cookies.
          </Disclaimer>
          <ButtonContainer>
            <Button width={'290px'} onClick={() => save()} primary>Entrar no site</Button>
          </ButtonContainer>
        </Container>
      </Overlay>
    </>
  );
};