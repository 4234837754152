import styled from 'styled-components'

export const MeContent = styled.div.attrs({
})`
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    margin-bottom: 46px;
    min-height: 80vh;
`

export const ContainerMe = styled.div.attrs({
})`
    display: flex;
    gap: 40px;
    margin-top: 10px;
`
export const IconContent = styled.div.attrs({
})`
    display: none;
    @media (max-width: 768px) {
        display: block;
}   
    `;