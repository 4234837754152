import styled from 'styled-components'

export const CardContainer = styled.div.attrs({
})` 
    display: flex;
    flex-wrap: wrap;
    padding: 56px 96px;
    max-width: 1512px;
    margin: 0 auto;
    gap: 24px;
    justify-content: center;
    @media (max-width: 600px) {
        padding: 16px;
        gap: 8px;
    }
`;
export const FormContent = styled.div.attrs({
})` 
   background-color: ${props => props.theme.palette.colors.white};
  
`;
export const Card = styled.div.attrs({
})` 
  background-color:${props => props.color ? props.theme.palette.sugestionsGradient[props.color] : props.theme.palette.primary.main};
  padding-left: 24px;
  display: flex;
  align-items: center;
  width: 235.2px;
  height: 136px;

  @media (max-width: 600px) { 
    max-width: 170px;
    padding: 12px;
  }
`;

export const CardTitle = styled.div.attrs({
})`
  color: ${props => props.theme.palette.colors.white};
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  flex-wrap: wrap;
  width: 102px;

`;