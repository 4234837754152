import React from "react";

import ContainerAuthenticated from "containers/Authenticated";

import { useHistory } from 'react-router-dom';
import { CartProductsContent, Content, DetailsInfoContent, OrderDetailsContent, OrderProductsContainer, SummaryContent, SummaryText } from "./styled";
import { DetailsTitle, FormContent, MeTitle } from "ui/styled";
import OrderStatus from "components/OrderStatus";
import OrderProducts from "components/OrderProducts";
import StatusBar from "components/StatusBar";
import Address from "components/Address";
import PaymentDetails from "components/PaymentDetails";
import PurchaseSummary from "components/PurchaseSummary";
import Footer from "components/Footer";
import CartProductList from "components/CartProductList";
import { cartItems } from "utils/products";

const orderDetails = {
    orderInfo: {
        status: "Aguardando pagamento",
        orderDate: "01/02/24",
        orderNumber: "123456789",
    },
    packageInfo: {
        packageNumber: 1,
        items: cartItems,
        orderDate: "01/02/24",
        deliveryDate: "02/03/24",
        shippingCost: "12,34",
        totalCost: "123,45",
        seller: "Alpha Comercio",
        deliveredBy: "Alpha Transportes"
    },
    address: {
        label: "Casa",
        name: "José Santos da Silva",
        CEP: "01234-567",
        street: "Rua dos Mares",
        number: "123456",
        city: "Parque do Litoral",
        state: "São Paulo/SP",
        complements: "Bloco 1 - Escada 2 - Apartamento 123"
    },
    payment: {
        method: "Cartão de Crédito"
    },
    summary: {
        productsTotal: "123,45",
        servicesTotal: "34,56",
        grandTotal: "157,89",
        quantity: 2
    }
};



export default function OrderDetails() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    return (
        <>
            <ContainerAuthenticated>
                <OrderDetailsContent>
                    <FormContent>
                        <MeTitle>Detalhe do pedido</MeTitle>
                        <OrderStatus status={orderDetails.orderInfo} />
                        <OrderProductsContainer>
                            <DetailsTitle>Pacote {orderDetails.packageInfo.packageNumber} </DetailsTitle>
                            <StatusBar>

                                <CartProductsContent>

                                    {
                                        cartItems.map(item => (
                                            <CartProductList items={item} noFreight />
                                        ))
                                    }
                                </CartProductsContent>


                                <SummaryContent>
                                    <Content>
                                        <SummaryText>
                                            Data do pedido: {orderDetails.packageInfo.orderDate}
                                        </SummaryText>
                                        <SummaryText>
                                            Data de entrega: {orderDetails.packageInfo.deliveryDate}
                                        </SummaryText>
                                        <SummaryText>
                                            Vendido por: {orderDetails.packageInfo.seller}
                                        </SummaryText>
                                        <SummaryText>
                                            Entregue por: {orderDetails.packageInfo.deliveredBy}
                                        </SummaryText>
                                    </Content>
                                    <Content>
                                        <SummaryText>
                                            Frete: R$ {orderDetails.packageInfo.shippingCost}
                                        </SummaryText>
                                        <SummaryText total>
                                            Total: R$ {orderDetails.packageInfo.totalCost}
                                        </SummaryText>
                                    </Content>
                                </SummaryContent>
                            </StatusBar>
                        </OrderProductsContainer>
                        <DetailsInfoContent>
                            <Address addressDetails address={orderDetails.address} borderDetails />
                            <PaymentDetails method={orderDetails.payment.method} />
                            <PurchaseSummary
                                productsTotal={orderDetails.summary.productsTotal}
                                servicesTotal={orderDetails.summary.servicesTotal}
                                qtd={orderDetails.summary.quantity}
                                grandTotal={orderDetails.summary.grandTotal}
                                orderDetails
                            />
                        </DetailsInfoContent>
                    </FormContent>
                    <Footer noFooter />
                </OrderDetailsContent>
            </ContainerAuthenticated>
        </>
    );
}