import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const MaterialCheckbox = styled(Checkbox)(({ theme, small, success }) => ({
  margin: 0,
  '& .MuiSvgIcon-root': {
    width: small ? '16px' : '24px',
    height: small ? '16px' : '24px',
    fontSize: small ? '16px' : '24px',
    color: theme.palette.colors.black,
  },
  color: theme.palette.colors.white,
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.colors.black,
    },
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ alignTop, bold }) => ({
  alignItems: alignTop ? 'flex-start' : 'center',
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
  },
}));