import React, { useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Terms() {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    
    const contactEmail = "contato@spiritsclub.com.br"

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>


                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }
                    
                    <TitleContent>
                        <Content>
                            <Title noSpace>Termos de uso</Title>
                            <p><strong>Última atualização:</strong> 14/09/2024</p>
                        </Content>
                    </TitleContent>


                    <p>Bem-vindo ao Prime Spirits Club! Ao acessar e utilizar nosso site, você concorda com os seguintes Termos de Uso. Caso não concorde com estes termos, por favor, não utilize o site.</p>

                    <h2>1. Aceitação dos Termos</h2>
                    <p>1.1. Ao acessar e usar o Prime Spirits Club (o “Site”), você concorda em cumprir e se sujeitar a estes Termos de Uso e nossa Política de Privacidade.</p>
                    <p>1.2. Reservamo-nos o direito de alterar estes Termos de Uso a qualquer momento. As alterações serão publicadas nesta página e entrarão em vigor imediatamente após a publicação. Seu uso contínuo do Site após tais modificações constitui sua aceitação das novas condições.</p>

                    <h2>2. Uso do Site</h2>
                    <p>2.1. O Site é destinado exclusivamente para a venda de bebidas importadas e produtos relacionados. Você concorda em utilizar o Site apenas para fins legais e de acordo com estes Termos de Uso.</p>
                    <p>2.2. É proibido usar o Site para qualquer atividade que seja ilegal, prejudicial ou que possa danificar a reputação do Prime Spirits Club.</p>

                    <h2>3. Conta e Segurança</h2>
                    <p>3.1. Para acessar certas áreas do Site, pode ser necessário criar uma conta. Você concorda em fornecer informações precisas e completas durante o processo de registro e em manter essas informações atualizadas.</p>
                    <p>3.2. Você é responsável por manter a confidencialidade de sua conta e senha e por todas as atividades que ocorram sob sua conta. Notifique-nos imediatamente sobre qualquer uso não autorizado de sua conta.</p>

                    <h2>4. Compra de Produtos</h2>
                    <p>4.1. Todos os preços e descrições de produtos estão sujeitos a alteração sem aviso prévio. O Prime Spirits Club reserva-se o direito de cancelar qualquer pedido se houver erros na descrição do produto ou no preço.</p>
                    <p>4.2. Ao fazer um pedido, você concorda em fornecer informações precisas sobre o endereço de entrega e outras informações necessárias para a conclusão da transação.</p>
                    <p>4.3. O Prime Spirits Club não se responsabiliza por atrasos na entrega causados por terceiros, incluindo transportadoras e serviços de correios.</p>

                    <h2>5. Propriedade Intelectual</h2>
                    <p>5.1. Todo o conteúdo disponível no Site, incluindo textos, gráficos, logotipos, imagens e software, é propriedade do Prime Spirits Club ou de seus licenciadores e está protegido por leis de propriedade intelectual.</p>
                    <p>5.2. É proibido reproduzir, distribuir, modificar ou criar trabalhos derivados do conteúdo do Site sem a permissão expressa por escrito do Prime Spirits Club.</p>

                    <h2>6. Isenção de Responsabilidade</h2>
                    <p>6.1. O Site e seu conteúdo são fornecidos “como estão” e “conforme disponíveis”. O Prime Spirits Club não faz garantias de qualquer tipo, expressas ou implícitas, sobre a precisão, integridade ou adequação das informações e produtos disponíveis no Site.</p>
                    <p>6.2. O Prime Spirits Club não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais que possam resultar do uso ou da incapacidade de uso do Site.</p>

                    <h2>7. Links para Sites de Terceiros</h2>
                    <p>7.1. O Site pode conter links para sites de terceiros. Esses links são fornecidos apenas para sua conveniência e não implicam endosse ou responsabilidade pelo conteúdo desses sites.</p>

                    <h2>8. Lei Aplicável e Jurisdição</h2>
                    <p>8.1. Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil, sem considerar seus conflitos de princípios legais.</p>
                    <p>8.2. Qualquer disputa decorrente ou relacionada a estes Termos de Uso será resolvida nos tribunais competentes da cidade São Paulo, e você consente com a jurisdição pessoal desses tribunais.</p>

                    <h2>9. Contato</h2>
                    <p>9.1. Se você tiver qualquer dúvida sobre estes Termos de Uso, entre em contato conosco através do e-mail <a href={`mailto:${ contactEmail }`}>{ contactEmail}</a> .</p>

                    <p>Por favor, revise este documento periodicamente para se manter atualizado com qualquer alteração.</p>


                </FormContent>
                {/* <Brands /> */}
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}