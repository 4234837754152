import styled from "styled-components";

export const PaymentMethodsContainer = styled.div.attrs({
})`
    background-color: ${props => props.theme.palette.paymentBackground.primary};
`;
export const FormContent = styled.div.attrs({
})` 
    max-width: 1512px;  
    padding: 32px 96px;
    margin: 0 auto;
    @media (max-width: 600px) {
        padding: 32px 24px;
    }
`;

export const MethodsContent = styled.div.attrs({
})`
  display: flex;
  flex-wrap: wrap;
  gap: 43.7px;
  margin-top: 23px;
`;

export const Method = styled.img.attrs({
})`
  width: 73px;
  height: 38px;
`;
