import styled from "styled-components";

export const ButtonContainer = styled.div.attrs({
})`
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  padding-top: 8px;
`
export const TitleConetent = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const TextAddress = styled.div.attrs({
})`
  font-family: MetronicPro;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  padding: 0 16px;
  color: ${props => props.theme.palette.orange.main};

`
export const FormContent = styled.div.attrs({
})`
    max-width: 460px;
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.palette.colors.white};
`;
export const FormContatContainer = styled.div.attrs({
})`
`;
export const Container = styled.div.attrs({
})`
  padding: 16px;
  max-width: 460px;
  flex: 1;
  background-color: ${props => props.theme.palette.colors.white};
   @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const Section = styled.div.attrs({
})`
  margin-bottom: 40px;
`;

export const Title = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-top: 16px;
`;

export const Detail = styled.div.attrs({
})`

  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
  margin-bottom: 16px;
  margin-top: 8px;
`;

export const IconList = styled.div.attrs({
})`
  display: flex;
  gap: 10px;
  margin-top: 16px;
`;
export const SupportContainer = styled.div.attrs({
})`
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;

  }
`;