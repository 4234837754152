import styled from 'styled-components'


export const DashboardMenuContainer = styled.div.attrs({
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${props => props.theme.palette.colors.black}80;
`;

export const DashboardMenu = styled.div.attrs({
    className: 'menu-contant'
})`           
    max-width: 389px;
    background: ${props => props.theme.palette.colors.backgroundgrey};
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const DashboardMenuHeader = styled.div.attrs({
})` 
    display: flex;
    align-items: center;
    justify-content: center; 
    padding: 32px 0;    
    position: relative;     
    background: ${props => props.theme.palette.white.main};
`;
export const DashboardHeader = styled.div.attrs({
})` 
    display: flex;
    padding: 16px 16px;
    flex-direction: column;
    margin-bottom: 40px;
`;

export const ButtonContent = styled.div.attrs({
})`
    display: flex;
    gap: 24px;
`;
export const DashboardMenuHeaderIcon = styled.img.attrs({
})`           
    right: 36px;
    position: absolute;
    cursor: pointer;
`;



export const DashboardMenuOption = styled.div.attrs({
})`           
    padding: 12px 8px;
    font-size: 15px;
    background-color: ${props => props.theme.palette.colors.white};
    cursor: pointer; 
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover{
        text-decoration: underline;
    }
    
`;
export const OptionTitle = styled.div.attrs({
})`
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.83px;
    letter-spacing: -0.02em;
    text-align: left;


`;
export const Title = styled.div.attrs({
})`
    font-family: DM Serif Display;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    text-align: ${props => props.centred ? 'center' : 'left'};
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 8px;

`;
export const MenuContentItems = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.marginTop ? '40px' : '0'};
    background: ${props => props.white && props.theme.palette.colors.white};
    padding: 8px 0 0;

`;

export const DashboardMenuContent = styled.div.attrs({
})` 
    flex:1;
    padding: 0 16px;
`;

export const DashboardMenuFooter = styled.div.attrs({
})`
    padding: 20px;
`;

export const DashboardVersionContent = styled.div.attrs({
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({
})`
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    text-align: center; 
`;
export const IconList = styled.div.attrs({
})`
  display: flex;
  gap: 32px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
    justify-content: center;
`;