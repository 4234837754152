import React from 'react'
import { DetailsTitle, Icon, MeContent } from 'ui/styled'
import FormCore from "../Form/Core";
import { ButtonContainer, Container, SupportContainer } from './styled';
import Button from 'components/Form/Button';
import Check from 'components/Form/Check';
import { FormContent } from './styled';
import { Section } from './styled';
import { Title } from './styled';
import { Detail } from './styled';
import { IconList } from './styled';
export default function Support() {
  const contactFormItems = [
    {
      ref: 'motivoContato',
      type: 'select',
      placeholder: 'Motivo do contato',
      options: [
        { value: 'entrega', title: 'Entrega' },
        { value: 'pagamento', title: 'Pagamento' },
        { value: 'outros', title: 'Outros' }
      ],
      full: true,
    },
    {
      ref: 'assunto',
      type: 'text',
      placeholder: 'Assunto',
      full: true,
    },
    {
      ref: 'mensagem',
      type: 'textarea',
      placeholder: 'Mensagem',
      full: true,
    }
  ];


  const contactInfo = [
    {
      type: 'telefone',
      title: 'Por telefone',
      details: [
        '0800-123-3030',
        '(11) 2345-6789 (Grande São Paulo)'
      ]
    },
    {
      type: 'email',
      title: 'Por email',
      details: [
        'atendimento@empresa.com.br'
      ]
    },
    {
      type: 'whatsapp',
      title: 'Por Whatsapp',
      details: [
        '(11) 98765-4321'
      ]
    },
    {
      type: 'redesSociais',
      title: 'Confira nossas redes sociais',
      icons: [
        { icon: '/icons/facebook-black.svg', link: 'https://www.facebook.com' },
        { icon: '/icons/youtube-black.svg', link: 'https://www.youtube.com' },
        { icon: '/icons/twitter-black.svg', link: 'https://www.twitter.com' },
        { icon: '/icons/instagram-black.svg', link: 'https://www.instagram.com' }
      ]
    }
  ];
  return (
    <>
      <SupportContainer>
        <Container>
          <DetailsTitle>Central de atendimento</DetailsTitle>
          {contactInfo.map((info, index) => (
            <Section key={index}>
              <Title>{info.title}</Title>
              {info.details && info.details.map((detail, index) => (
                <Detail key={index}>{detail}</Detail>
              ))}
              {info.icons && (
                <IconList>
                  {info.icons.map((icon, index) => (
                    <Icon key={index} src={icon.icon} onClick={() => { }} />
                  ))}
                </IconList>
              )}
            </Section>
          ))}
        </Container>
        <FormContent>
          <DetailsTitle>Formulário de contato</DetailsTitle>
          <FormCore formItems={contactFormItems} />
          <ButtonContainer>
            <Button primary>Enviar</Button>
          </ButtonContainer>
        </FormContent>
      </SupportContainer>
    </>
  )
}
