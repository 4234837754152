import styled from "styled-components";

export const PaymentPixContainer = styled.div.attrs({
})`
  display: flex;
  margin-top: 32px;
  gap: 34px;
  width: 100%;
  padding: 0 24px;
  flex-wrap: wrap;
`
export const PaymentPixQRCodeContent = styled.div.attrs({
})`
  @media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  }
`;

export const QRCodeWrapper = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.black};
  width: 208px;
  height: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PixTitle = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin-bottom: 16px;
`;

export const PixInfo = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const List = styled.ol.attrs({
})`
  list-style-type: decimal;
  font-size: 16px;
  margin-top: 24px;
  & > li {
  }
`;

export const ListItem = styled.li.attrs({
})`
  font-size: 16px;
  font-weight: 400;
  margin-left: -8px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
`;