import React from 'react'
import ContainerAuthenticated from 'containers/Authenticated'
import CarouselApp from 'components/carousel'
import { FormContent } from 'ui/styled';
import { useParams } from 'react-router-dom';
import { BrandContent, BrandText, Title, TextContainer, LegacyText, LegacyContainer, LegacyImg, DrinkContainer } from './styled';
import Video from 'components/Video';
import History from 'components/History';
import ProductList from 'components/ProductList';
import { drinks } from 'utils/drinks';
import PaymentMethods from 'components/PaymentMethods';
import Footer from 'components/Footer';

export default function BrandDetails() {
  const { name } = useParams()
  const brandName = name
    .replace(/[^\w\s]/gi, '')
    .replace('-', '')
    .toLowerCase();
  const brandNameRender = name.replace('-', ' ').replace(/\b\w/g, char => char.toUpperCase());
  console.log(brandName);
  const productDetails = {
    banner: [
      {
        url: 'https://s3-alpha-sig.figma.com/img/ea93/4838/49948f90de6859e2a436dfc8c662e1a7?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JfIywM64uZ1ERDAAgeukKxAWFACOzHovWKmW1vFirkYUYANEQ~yOXptY1h1-ETMh27cUa1n7LARyM8J4jKNjTCSrA4FGO4e~9JiaHtCMAlXsw-csY~Aih2uYmAoBr22iB4KSALK6qmMm~kHGm1kXRWvl9nejtfmoec586wzP8BlB6NqXndHzr9G5SqkKpBZzYdWyyb~ZtQNNSgLLmNzcfFQDAo0yd9yK4IF~ckZD0Y~FvBgRbnd93VySEKwxZUqiIRi07JEijQrd8OSTv01TIiaV7kj2a07ZS55BwJCSn3upFmVdWqL0vLS3xGw-kJ8bQDfCrMeNt9HED74DZIGPfw__',
        alt: 'First slide'
      },
      {
        url: 'https://beamsuntory.widen.net/content/rhjhn0ut91/jpeg/JimBeamWhite_KV_FamilyMade_Illustrated_Horizontal_092023.jpeg?w=2048',
        alt: 'Second slide'
      }
    ],
    title: "Jim Beam",
    description: "O bourbon mais vendido do mundo, com sua receita original mantida há mais de 200 anos, é feito para curtir junto!",
    video: "/images/video.mp4", // Caminho para o vídeo
    family_title: "O legado da família Beam",
    family_description: "Sete gerações de Mestres Destiladores Jim Beam® constituíram um legado familiar de 200 anos. A tradição continua com a oitava geração em treinamento para ter sucesso. Ao longo dos anos, Jim Beam® representou a indústria como a maior empresa de bourbon do mundo e aperfeiçoou o ofício de fazer bourbon, protegê-lo e transmiti-lo para as próximas gerações. Jim Beam® ainda usa a mesma receita original usada por Jacob Beam quando vendeu seu primeiro barril de bourbon em 1795.",
    family_images: [
      "https://s3-alpha-sig.figma.com/img/dd4d/cb50/434cacfcdfbabaf77898079ebf8d345b?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RpcpWIz1O-Jnk7Zo1TRYgSm5k5RmuAB1zT0oL7nLXIIHayXVP6MNOwAqh~nKMjYtio7krN~Hs7XEBFid9xjJVaRF5xpo8YH3RfV0Myaanyy7CXOYZB-4ijMg50SsJ~EEQesS39GA7zmyHdgb47kZf3zycWnsKAUtft2yCLwjB6vnOFU3A4q1ursfkAukxw4WdUPaQr7Gr-uKfG~gp4FmCeZcmbw8nNaj6hlspYNFsQd~NQLC8y7FKubvm0h21ZtJD0E1yvo7xypWH5y5j~o3IDLKwsSM713hQVllsY8Dq7tvFKqctAQsTVziihvVcByw4tVphju3E62KNdKmweFVHw__",
      "https://s3-alpha-sig.figma.com/img/ab37/5cc6/1b022c6f4bcc96fa11f3d60f4b5a292d?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ey52ilhpH7Iuzvzf5FAv7xUeRy3tZSxSAizE5i6TVn9nq9~zAM5WFBEGLA5Dz3v-vParIl0ZEXU96bIDJ2ZIBl~u98X8P1Y62-sljbUwZGvDqDbZuzi0gT39d4KFkK5z0nhUzSTrrz6oa96gAzzlk4U64NrvDbiXovGJPE0e3nspVTTWzfdqXpTqRcOp22O2ko8u~Sf4tI-NFA2SIOdPMVyLu~IU9SD2KLnMrG3B8tDC6OuASAC2MrYZe3ERWWoZApyBUagaVFgQ9es33esJas2BZiHknsZJ1I8tZ8jhSqAiBPPyKjTkqdLH~icXAV-34MSB5y~JjyMvZW14qtG2Kg__",
      "https://s3-alpha-sig.figma.com/img/6d7f/2f4c/dc81401172c16cbf81dcaa8277a5fdab?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BavZmQoR-slQZJZ86XnD6dlbfxXQzEFO8iPkCPJNDPOa9fm7GJc15rywTHN67TCRzmKIZBI~g7B7l1qLjYhdh9-LccbtOBTxV7WdETtq5PR4-dKvkKQ-vv1pIPlmGDqE50acBcYs27LQOPvz86aa2zi8qiVbYbESPdwbsjM8Lb90l4grJK6wTMx8T~ZPTOgv0uR0b5Jm1JvU51qgjeCAcLc-AI2yAIWKyHI9dUwK0eO0KKEO5giZE-EJCulgFWSHcYz0lAVTHSopmhQgXHtchakDch4ROXHYkqqMe5eeg9sKqowf44Vo14i07EU2j1H-kC5JJj7WMtCrAqPXbrchGw__",
      "https://s3-alpha-sig.figma.com/img/4884/f1ef/88ed3edcaa1497ab38b323fddc05ce5f?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Lw5~pUeTpgEsiHvWFcajTlbzWPq42yfWV-fRigLnnIuxgV5LxwUiVE0S0SzjNRM82ZM4qW1tn3DKNXa6v6GotZ1K5RGh8-In6mSdPoxDbBh5vkr4vqR5uQ9gwtkrZhPGs0eCxTzOSONzQdLGwUVZ-3qZAvlFPqa4-x2DXPbITuTMd6lLYDp7pip6t-7vTnJ0LiBKPgBWuJOeD5F~DMbR0fnxbISvhHECwkZUQE~MHedQ-ocfRrBnWq9yhRCmLNwElA~X8WKH1EPIr9DmmkIs2WSIFsFnflZJDczUPn~wb8wJFrsLkrhukSaAezwQ-5GDZ6k-Jx7prCs1~98ZER8Q8g__",
      "https://s3-alpha-sig.figma.com/img/bed4/f146/3462ab8f3579a607ec4da42e89d6b69a?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FRoxxPMjUVsRsZf9OgRlwZi-akogz~SrNWHGiYAxdGWhpbMnynFp-q91m1pHCDiAjcevGrcSP79gJOW3egbMynabTuwJJ63FEz5TbSumy0LGZCzp0mkihH~oC6XY13CBbE3cU2Yd6at1M41Se4xQRmEhHKny6W~lgu4KDO39puiJSLImAAKInt4JarONO495xgWbul4hnYe3LCsKbQXSl8-d6DO3kbHasOKDW~NcJFHRwYNQTRlBnpFMiOrm8qgnPJ1-v~VSu-0dpUeQFqY3YP7ync2qZnJm2mCdh~HdwZls-bbklgS197ExziHTZr9bQwkbmkp8r-3HPaIcQCtebQ__",
      "https://s3-alpha-sig.figma.com/img/6f73/f4c1/01d7628130f1020aa344f4eda6298f01?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eqHwijtWWWEc6gwUgrE4F-5TQOfgpAg0OQLH-Mv~7xFAEXzCaw9SYfGvudgULKpnAt~TJjeY1wzM3GwogQKjXhRXg4SzvLq1iRDoJ9jmjFUV3ZkvgfHiUKnOZlMyZnwVw7PFVTo7sARd4Mu-29~my6zpcuOgMjukjfpU9dxpav9iOw0D58xzE~Po5E2p4Ij1Syo30LeZo7~wQNa7n4KGhIm~agcj7sUH3gGaosIFCFmvf65aCTHLYOg1BjebjsBkdiX3CZh8qwBR-nr7Js3eytWbGeStbrJF-E7qMZ~GZfieUFPQWLd7oWCgFkkNQ2tOF1JSHN1U9FoMEsWWDwKSUA__",
      "https://s3-alpha-sig.figma.com/img/4b6c/8501/3c413895db8e24ee1a14f33bf0e5c85f?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nv-ecGxS86jsz548u1-NWjXU~IlCDESS0K816HVZ-SJyQ1~trYwThigawluQFjFjYuMzrDJOYytLj0GtLHRkUwk50dMkJwODGLYm621Ux6x-ixzWZ~raceKOzxTpn5dF7fEwkXsed9CtzzoEGmR9EnuwMHfaCDL552BSAxbrHnwBtkRvDZFLWQ19M~O0xapDryjp6KRIdLhakTkLn-5Grj1PeT3~oJch31E0SDYwjZHLgQ6s~CnQA9MfacVexbpMirTW~FOP0~jA5Eu5ntNAxjUBj~yC7GdCgakybD8R4lBr8IsJ5hrtQq1B-3APQPrYsXm5bszA3MrLd7n3B~GdJA__"
    ],
    history_title: "A história do Bourbon nº1 do mundo",
    history_images: [
      { year: 1788, image: "/images/history-image1.svg", description: "Jacob Beam se instala em Kentucky apenas com um destilador de cobre e seu conhecimento" },
      { year: 1795, image: "/images/history-image2.svg", description: "Jacob vende seu primeiro barril de bourbon" },
      { year: 1800, image: "/images/history-image3.svg", description: "As quatro gerações seguintes seguem os passos de Jacob enfrentando altos e baixos de uma nação em fase de probabilidades" },
      { year: 1933, image: "/images/history-image4.svg", description: "Após 120 dias após a revogação da lei seca Jim Beam volta a destilar em Clermont em funcionamento" },
      { year: 1992, image: "/images/history-image5.svg", description: "Mestre destilador Booker Noe lança a primeira pequena leva de bourbons superpremium" },
      { year: 2000, image: "/images/history-image6.svg", description: "Presente: Fred Noe, 7ª geração continua a tradição de 200 anos na história da família" }
    ],
    product_image: [
      {
        url: 'https://s3-alpha-sig.figma.com/img/826d/ed8d/2688f91b05db5b83d568c1c3bec789a9?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZQ~Hu-IoDyz0fx~3luUHtPOG47q9p-OKTvFzYf9GcaElP09PuaHNLbGHQrJq-gm88nhZTUyODa1B4uDsehRSKCelEeFN36Rv-csSEc4nW1vPz91S3R1vF~eX7T5mRPEFXOf2SNmFFF6F93sOo204WBIPhQq1HqI6RBOKcHpFETr~VaxYQOVRieuy0zpV3ZMsmJZiRU49syGVXZtXk5HUf8Uidiqfh-l9SkaixQtbMXeXbnYtuPJfaRNDV7Nlc2wq7W99kqyUdgjkvyQ5oAVJgVe0u-pi0melRMHDEhwJ69c2iBw~S1gO6M8oRlE4rhv6UYmrDah2R~KoQEdVdZeDmg__',
        alt: 'Second slide'
      },
      {
        url: 'https://s3-alpha-sig.figma.com/img/51e0/cc05/7453e200807ba94c277c381c93025176?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZiINTvnxAFKF0-tr-2aPv3OO7~fX~Z1TvK9mJ~2EiV9u6O~goByStiErTSsc~Pz-BKrw3EUcwq7DcKeoFlbT9jbe8BnBQSw0Uk4MSQ~D3aryI6YItxrjaoZvs9lTPxRTmD9cKZrpAn2H0Ts46ezWEMiBbozclrpWjnzDjuhjOqpOo-HIaGWAtPG0OK3vStG~BX83VwyF5jC0iEAHqKTY4pvaI5~mT0vICqj4jh-SN2Ivf69buxIzY5Biba9lZIJ9Xo0wM3LWD~pRUdU4y3TEYrCc47wdZr1mNwtxWVYRhoWmN6EbhnfZeF88sQEqh-CRPn9O7iNyfKgTFOEGMtphEw__',
        alt: 'First slide'
      },
    ],
  };


  return (
    <>
      <ContainerAuthenticated noHeader>
        <CarouselApp imgs={productDetails.banner} />
        <BrandContent>
          <FormContent>
            <TextContainer>
              <Title brand={brandName}>
                {brandNameRender}®
              </Title>
              <BrandText>
                {productDetails.description}
              </BrandText>
            </TextContainer>
            <Video src={productDetails.video} />
          </FormContent>
        </BrandContent>
        <FormContent>
          <Title brand={brandName}>{productDetails.family_title}</Title>
          <LegacyText>
            {productDetails.family_description}
          </LegacyText>
          <LegacyContainer>
            {
              productDetails.family_images.map((image, index) => (
                <LegacyImg key={index} src={image} />
              ))
            }
          </LegacyContainer>
        </FormContent>
        <History history_images={productDetails.history_images} history_title={productDetails.history_title} brand={brandName} />
        <DrinkContainer>
          <FormContent>
            <CarouselApp imgs={productDetails.product_image} />
          </FormContent>
        </DrinkContainer>
        <ProductList title="Produtos relacionados" products={drinks} />
        <PaymentMethods />
        <Footer />

      </ContainerAuthenticated>
    </>
  )
}
