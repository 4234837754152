import React, { useEffect, useMemo, useState } from "react";

import {
    FormContent,
    ProductContent,
    ProductDetailsContent,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";

import Footer from "components/Footer";
import Breadcrumb from "components/Breadcrumb";
import ImageGallery from "components/ImageGallery";
import ProductInfo from "components/ProductInfo";
import ProductDescription from "components/ProductDescription";
import useWindowSize from "utils/useWindowSize";
import ProductList from "components/ProductList";
import { drinks } from "utils/drinks";
import { products } from "utils/products";
import Banner from "components/Banner";
import FlavorProfile from "components/FlavorProfile";
import DrinksCarousel from "components/DrinksCarousel";
import { ReadOne } from "services/products";
import { normalizeStrapiRegister } from "utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";



export default function ProductsDetails() {
    const windowSize = useWindowSize();

    const { id } = useParams()

    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(false)



    const images = useMemo(() => {
        return !product?.image_link ? [] : [ product?.image_link ]
    }, [product]);
    // const product = products[0];

    const flavorImage = "/images/flavor.svg";

    const init = async () => {
        if(!loading && id){
            setLoading(true)
                const result = await ReadOne(id)
                const normalResult = normalizeStrapiRegister(result)
                console.log("normalResult", normalResult)
                setProduct(normalResult)
            setLoading(false)
        }
    }

    useEffect(() => {init()},[id]) 

    return (
        <>
            <ContainerAuthenticated>
                <ProductDetailsContent>
                    <FormContent>
                        <Breadcrumb />
                        <ProductContent>
                            <ImageGallery images={images} />
                            <ProductInfo {...product} product={product} />
                        </ProductContent>
                    </FormContent>
                    {/* <Banner /> */}
                </ProductDetailsContent>
                <ProductDescription product={product} />
                {/* <FlavorProfile flavorImage={flavorImage} /> */}
                {/* <DrinksCarousel /> */}
                <ProductList title="Produtos relacionados" products={drinks} />
                <Footer noInfo />
            </ContainerAuthenticated>
        </>
    );
}