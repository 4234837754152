import styled from "styled-components";

export const FlavorProfileContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  padding: 55px 0px 55px 0px;
`;

export const FlavorProfileTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 40px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
  margin-bottom: 16px;

`;

export const FlavorProfileImg = styled.img.attrs({
})`
  max-width: 100%;
  max-height: 238px;
  object-fit: cover;
`;