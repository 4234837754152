import styled from 'styled-components'

export const SideBackgroundImageContainer = styled.div.attrs({
})`          
    background: url(${props => props.forgot ? '/images/background.svg' : '/images/background-two.svg'}) no-repeat center center;
    min-height:100vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    @media(max-width: 767px){ 
        position: relative;
        min-height:initial; 
        width: 100%;

    }
`;


export const FormContent = styled.div.attrs({
})`           
    min-height:100vh;
    padding: 60px 0;
    display: flex;
    background: ${props => props.theme.palette.colors.backgroundgrey};
    flex-direction: column;
    ${props => props.login && `
    justify-content: center;
    align-items: center;
    background: linear-gradient(
    to right,
    ${props.theme.palette.gradient.main} 0%,
    ${props.theme.palette.gradient.main} 12.5%,
    ${props.theme.palette.gradient.primary} 12.5%,
    ${props.theme.palette.gradient.primary} 25%,
    ${props.theme.palette.gradient.secondary} 25%,
    ${props.theme.palette.gradient.secondary} 37.5%,
    ${props.theme.palette.gradient.tertiary} 37.5%,
    ${props.theme.palette.gradient.tertiary} 50%,
    ${props.theme.palette.gradient.tertiary} 50%,
    ${props.theme.palette.gradient.tertiary} 62.5%,
    ${props.theme.palette.gradient.secondary} 62.5%,
    ${props.theme.palette.gradient.secondary} 75%,
    ${props.theme.palette.gradient.primary} 75%,
    ${props.theme.palette.gradient.primary} 87.5%,
    ${props.theme.palette.gradient.main} 87.5%,
    ${props.theme.palette.gradient.main} 100%

  );`}
    @media(max-width: 767px){
        padding: 16px;
    }
`;
export const AppLogo = styled.img.attrs({
    src: '/logo1024.png',
    width: 120
})`           
`;

export const Content = styled.div.attrs({
})`           
    overflow:hidden;
`;

export const Touch = styled.div.attrs({
})`           
    cursor: pointer;
`;

export const ColContainer = styled.div.attrs({
})`           
    display: flex;
    @media(max-width: 767px){
        flex-direction: column;
    }
    ${props => props.reverse && `
        flex-flow: row-reverse;
    `}
`;