
import styled from "styled-components";

export const OrderDetailsContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.gray.gray40};
`;
export const OrderProductsContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.white.main};
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
export const SummaryContent = styled.div.attrs({
})`
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  margin-top: 16px;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SummaryText = styled.span.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: ${props => props.total ? 700 : 400};
  line-height: 20px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
`;

export const Content = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 12px;
}
`;

export const DetailsInfoContent = styled.div.attrs({
})`
  display: flex;
  margin-top: 10px;
  gap: 24px;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
}
`;
export const CartProductsContent = styled.div.attrs({
})`    
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    padding: 16px;
    max-width: 800px;
    @media (max-width: 768px) {
    width: 100%;
    padding: 0;
}
`;