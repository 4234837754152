import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddressContainer, AddressContent, AddressInfoContent, AddressItems, AddressItemsTitle } from './styled';
import Check from 'components/Form/Check';
import Button from 'components/Form/Button';
import { DetailsTitle } from 'ui/styled';

export default function Address({ address, isSelected, onSelect, next, addressDetails, borderDetails, noMargin }) {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const addressFields = [
    { title: 'Nome', value: address.name },
    { title: 'CEP', value: address.CEP },
    { title: 'Endereço', value: `${address.street}, ${address.number}, ${address.city} - ${address.state}` },
    { title: 'Complementos', value: address.complements }
  ];

  return (
    <AddressContainer addressDetails={addressDetails}>
      <AddressContent noMargin={noMargin}>
        {!addressDetails && (
          <Check
            checked={isSelected}
            onChange={onSelect}
            alignTop
          />
        )}
        <AddressInfoContent>
          {addressDetails && (
            <DetailsTitle border>
              Endereço
            </DetailsTitle>
          )}
          <AddressItems borderDetails={borderDetails} addressDetails={addressDetails}>
            {address.label}
          </AddressItems>
          {addressFields.map((field, index) => (
            <AddressItems key={index} borderDetails={borderDetails}>
              <AddressItemsTitle>{field.title}:</AddressItemsTitle>
              {field.value}
            </AddressItems>
          ))}
        </AddressInfoContent>
      </AddressContent>

      {!addressDetails && <Button primary noRadius onClick={() => next()}>Usar este endereço</Button>}

    </AddressContainer>
  );
};
