import styled from "styled-components";

export const SuccessContent = styled.div.attrs({
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
`
export const SuccessTitle = styled.div.attrs({
})`

  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;

`

export const OrderNumber = styled.div.attrs({
})`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  color: ${props => props.theme.palette.colors.black};
`

export const SuccessMessage = styled.div.attrs({
})`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  color: ${props => props.theme.palette.colors.grey};
  margin-top: 24px;
`

export const FormSpace = styled.div.attrs({
})`
  margin: 32px 0;
  border: 0.5px solid ${props => props.theme.palette.gray.gray100};
  width: 100%;

`