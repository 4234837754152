import styled from "styled-components";

export const ButtonContainer = styled.div.attrs({
})`
  display: flex;
  gap: 24px;
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  padding-top: 8px;
`
export const TitleConetent = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`
export const TextAddress = styled.div.attrs({
})`
  font-family: MetronicPro;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  padding: 0 16px;
  @media (max-width: 600px) {
    padding: 0;
    margin-bottom: 16px;
  }

`
