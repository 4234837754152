import React, { useState } from "react";
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import {
    InputContainer,
    MaterialSelect,
    StyledInputLabel
} from "./styled";
import { ThemedComponent } from "ui/theme";

export const Select = ({ placeholder, options, small, value, onChange, id, secondary, width, backgroundgrey, noRadius, padding, lightgrey, label }) => {

    const [opened, setOpened] = useState(false);

    const toggleOpen = () => {
        setOpened(!opened);
    };

    const optionAction = item => {
        if (onChange && typeof onChange === 'function') {
            onChange(item.target.value);
            console.log(item.target.value);
        }
        toggleOpen();
    };

    return (
        <>
            <ThemedComponent>
                <InputContainer>
                    {label && <StyledInputLabel htmlFor={`select-${id}`}>{placeholder}</StyledInputLabel>}
                    <FormControl variant="standard" fullWidth>
                        <MaterialSelect
                            small={small}
                            labelId={id}
                            id={`select-${id}`}
                            value={value}
                            onChange={optionAction}
                            placeholder={!label ? placeholder : ''}
                            disableUnderline
                            secondary={secondary}
                            displayEmpty
                            width={width}
                            backgroundgrey={backgroundgrey}
                            noRadius={noRadius}
                            padding={padding}
                            lightgrey={lightgrey}
                        >
                            {!label && <MenuItem value="" disabled>{placeholder}</MenuItem>}
                            {options?.map((item, key) => (
                                <MenuItem key={key} value={`${item.id}`}>{item?.title}</MenuItem>
                            ))}
                        </MaterialSelect>
                    </FormControl>
                </InputContainer>
            </ThemedComponent>
        </>
    );
}

Select.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    small: PropTypes.bool,
    secondary: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    externalLabel: PropTypes.bool,
};

Select.defaultProps = {
    placeholder: '',
    options: [],
    value: '',
    small: false,
    secondary: false,
    onChange: undefined,
    externalLabel: false,
};

export default Select;