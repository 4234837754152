import styled from "styled-components";

export const Content = styled.div.attrs({
})`   
  width: 100%;
  background: black url(/images/image-background.svg) no-repeat center / cover;

`;

export const Title = styled.div.attrs({
})`            
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 56px;
  text-align: center;
  color: ${props => props.theme.palette.colors.white};
  max-width: 840px;
  min-width: 308px;
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 32px;
    width: 100%;
    padding: 0 16px;
  }
`;

export const Container = styled.div.attrs({
})` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 24px;
  }
  
`;

export const LoginContent = styled.div.attrs({
})` 
    background-color: ${props => props.theme.palette.colors.white};
    max-width: 408px;
    padding: 16px 16px 24px 16px;
    display: flex;
    flex: 1;  
    flex-direction: column;
    gap: 24px;
    min-width: 408px;
    @media (max-width: 600px) {
      min-width: 100%;

    }

`;




