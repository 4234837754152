import React from 'react'
import { PaymentContainer, PaymentText } from './styled'
import { DetailsTitle } from 'ui/styled'
import Button from 'components/Form/Button'

export default function PaymentDetails({ method }) {
  return (
    <>
      <PaymentContainer>
        <DetailsTitle border> Pagamento</DetailsTitle>
        <PaymentText>Forma de pagamento: {method}</PaymentText>
        <Button nospace info>Acessar meu boleto</Button>
      </PaymentContainer>
    </>
  )
}
