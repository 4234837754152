import styled from 'styled-components';

export const TimerContainer = styled.div.attrs({

})`
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
`;

export const TimerLabel = styled.span.attrs({
})`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.palette.gray.gray800};
  `;

export const TimerDisplay = styled.span.attrs({
})`
  
  color: ${props => props.theme.palette.gray.gray800};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-left: 8px;
`;