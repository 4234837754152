
import styled from 'styled-components'

export const ShippingDeadlineContainer = styled.div.attrs({
})`
  background-color: ${props => props.full ? props.theme.palette.colors.backgroundgrey : props.theme.palette.colors.white};
  padding: 16px 16px 16px 16px;
  ${props => props.full && 'width: 100%;'}
  @media (max-width: 768px) {
    margin-top: 16px;
  }
`;
export const CepContent = styled.div.attrs({
})` 
  display: flex;
  align-items: end;
  gap: 4px;
  position: relative;
  width: 100%;

`;
export const InputContent = styled.div.attrs({
})` 
  width: 260px;
  @media (max-width: 768px) {
    width: 170px;
  }
`;
export const ButtonLink = styled.div.attrs({
})` 
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 22px;
  right: 0;
  position: absolute;
`;

export const Section = styled.div.attrs({
})` 
  margin-top: ${props => props.noSpace ? '0px' : '32px'}
`;
export const CheckContent = styled.div.attrs({
})` 
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray300};
  padding-bottom: 16px;
`;
export const DeliveryText = styled.div.attrs({
})` 
  font-family: MetronicPro;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${props => props.theme.palette.gray.gray800};

`;
export const DeliveryTitle = styled.div.attrs({
})` 
  font-family: MetronicPro;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: ${props => props.theme.palette.colors.black};
  margin-bottom: 16px;
`;


