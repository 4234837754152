
import styled from "styled-components";

export const StatusContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  width: 100%;
  padding: 16px;
`;

export const StatusTitle = styled.div.attrs({
})`

  font-size: 22px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: ${props => props.theme.palette.primary.main};
`;

export const StatusText = styled.div.attrs({
})`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
  margin-top: 8px;
`;
export const StatusTextContent = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;