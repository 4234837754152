import React, { useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Products() {
    const location = useLocation();

    const [sortOption, setSortOption] = useState(null);
    const [isResponsiveActive, setIsResponsiveActive] = useState(false);
    const windowSize = useWindowSize();

    const [search, setSearch] = useState("")

    const [products, setProducts] = useState([])

    const [loading, setLoading] = useState(false)

    const handleSortChange = (selectedSortOption) => {
        setSortOption(selectedSortOption);
    };

    const handleResponsive = () => {
        setIsResponsiveActive(!isResponsiveActive);
    };

    const init = async () => {
        if(!loading){
            setLoading(true)
                const result = await Read()
                const normalResult = normalizeStrapiList(result)
                console.log("normalResult", normalResult)
                setProducts(normalResult)
            setLoading(false)
        }
    }

    const sortProducts = (a, b) => {

        if(sortOption === 'price-asc'){
            return a.price - b.price
        }
        if(sortOption === 'price-desc'){
            return b.price - a.price
        }
        if(sortOption === 'name-asc'){
            return a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase());
            
        }
        if(sortOption === 'name-desc'){
            return b?.title?.toLowerCase().localeCompare(a?.title?.toLowerCase());
        }

        return 0;
    }

    useEffect(() => {init()},[]) 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const searched = queryParams.get('search');
        // console.log('SSS:', searched);
        setSearch(searched)
    }, [location.search]); 

    const filterExpression = item => {
        return ( !search || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(search.toLowerCase()) !== -1 ).length > 0)
    }

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>


                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }
                    
                    <TitleContent>
                        <Content>
                            {
                                search ? 
                                <Title noSpace>Resultado da busca "{search}"</Title>
                                    :
                                <Title noSpace>Lista de produtos</Title>
                            }
                            <ProductQuantity>{products?.filter(filterExpression)?.length||0} Produtos encontrados</ProductQuantity>
                        </Content>
                        <Select
                            placeholder="Ordenar produtos por"
                            options={sortOptions}
                            value={sortOption}
                            onChange={handleSortChange}
                        />
                    </TitleContent>
                    <BodyContent>
                        {/* {windowSize.width < 600 && (
                            <ButtonLink onClick={handleResponsive}>Ver filtros</ButtonLink>
                        )}
                        {(windowSize.width > 600 || isResponsiveActive) && (
                            <SideBar isResponsiveActive={isResponsiveActive} handleResponsive={handleResponsive} />
                        )} */}
                        <ProductContent>
                            {products?.sort(sortProducts)?.filter(filterExpression)?.map((tire, index) => (
                                <ProductCard big key={index} {...tire} />
                            ))}
                        </ProductContent>
                    </BodyContent>

                </FormContent>
                {/* <Brands /> */}
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}