import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

export const MaterialSelect = muiStyled(Select)(({ theme, ...props }) => ({
  fontSize: '16px',
  width: props.width ? props.width : '260px',
  color: theme.palette.colors.black,

  '& .MuiSelect-select': {
    padding: props.padding ? props.padding : '12.5px 14px',
    backgroundColor: props.backgroundgrey ? theme.palette.colors.backgroundgrey : props.lightgrey ? theme.palette.gray.gray100 : theme.palette.colors.white,
    borderRadius: props.noRadius ? '' : '8px',
    color: theme.palette.colors.grey,
  },
}));

export const InputContainer = styled.div`

  width: ${props => props.full && '100%'};
`;

export const StyledInputLabel = styled(InputLabel)(({ theme, grey, top }) => ({
  marginTop: (top && top) || '0',
  marginBottom: '2px',
}));
