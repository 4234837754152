import React, { useState } from 'react';
import { StatusBarContainer, StatusContent, StatusItem, StatusItems } from './styled';



const StatusBar = ({ children }) => {
  const [activeStatus, setActiveStatus] = useState('Pedido recebido');

  const statuses = [
    'Pedido recebido',
    'Pagamento aprovado',
    'Nota fiscal emitida',
    'Enviado a transportadora',
    'Pedido entregue'
  ];

  return (
    <StatusBarContainer>
      <StatusItems>
        {statuses.map(status => (
          <StatusItem
            key={status}
            active={status === activeStatus}
            onClick={() => setActiveStatus(status)}
          >
            {status}
          </StatusItem>
        ))}
      </StatusItems>
      <StatusContent>
        {children}
      </StatusContent>
    </StatusBarContainer>
  );
};

export default StatusBar;