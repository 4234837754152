import React from 'react'
import { FormBorder, HistoryContainer, HistoryContent, HistoryContentContainer, HistoryImg, HistoryText, HistoryTitle, HistoryYear } from './styled'
import { FormContent } from 'ui/styled'

export default function History({ history_images, history_title, brand }) {
  return (
    <>
      <HistoryContainer brand={brand}>
        <FormContent>
          <HistoryTitle brand={brand}>{history_title}</HistoryTitle>
          <HistoryContentContainer>

            {
              history_images.map((item, index) => {
                return (
                  <HistoryContent key={index}>
                    <HistoryImg src={item.image} />
                    <HistoryYear brand={brand}>{item.year}</HistoryYear>
                    <FormBorder brand={brand} />
                    <HistoryText brand={brand}>{item.description}</HistoryText>
                  </HistoryContent>
                )
              })
            }

          </HistoryContentContainer>
        </FormContent>
      </HistoryContainer>
    </>
  )
}
