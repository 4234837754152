import styledCmp from 'styled-components'
import ReactLoading from 'react-loading'

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Load = styledCmp(ReactLoading).attrs(props => ({
    type: 'spin',
    color: props.outline ?
        (
            props.primary ? props.theme.palette.primary.main :
                (props.secondary ? props.theme.palette.secondary.main : props.theme.palette.info.main)
        )
        : props.theme.palette.primary.contrastText,
    height: 20,
    width: 20
}))`          
`;

export const ColorButton = styled(Button)(({ theme, nospace, width, small, noRadius, full, between, start, big }) => ({
    width: width ? width : '100%',
    height: small ? '32px' : big ? '64px' : full ? '100%' : '48px',
    minWidth: full ? '100%' : 'auto',
    marginTop: nospace ? '0px' : '12px',
    textTransform: 'none',
    borderRadius: noRadius ? '0px' : '4px',
    display: 'flex',
    justifyContent: between ? 'space-between' : start ? 'flex-start' : 'center',

}));
