import React from 'react';
import { FormSpace, OrderNumber, SuccessContent, SuccessMessage, SuccessTitle } from './styled';
import { Icon } from 'ui/styled';
import Button from 'components/Form/Button';
import PaymentPix from 'components/PaymentPix';

export default function PaymentSuccess({ method, orderNumber }) {

  return (
    <>
      <SuccessContent>
        <Icon src="/icons/success.svg" />
        <SuccessTitle>{method === 'pix' ? 'Oba, falta pouco! Seu pedido está quase concluído' : 'Pedido realizado com sucesso!'}</SuccessTitle>
        <OrderNumber>Número do pedido: {orderNumber}</OrderNumber>
        {
          method === 'boleto' ? (
            <>
              <SuccessMessage>
                Obrigado por comprar conosco!<br />
                Pague seu boleto e acompanhe o status do seu pedido
              </SuccessMessage>
              <FormSpace />
              <Button width={'340px'} primary>Imprimir boleto</Button>
              <Button width={'340px'} info>Ver status do pedido</Button>
            </>
          ) : method === 'cartão de crédito' ? (
            <>
              <SuccessMessage>
                Obrigado por comprar conosco!<br />
                Acompanhe o status do seu pedido
              </SuccessMessage>
              <FormSpace />
              <Button width={'340px'} info>Ver status do pedido</Button>
            </>
          ) : method === 'pix' ? (
            <>
              <PaymentPix />
              <FormSpace />
              <Button width={'340px'} primary>Continuar navegando</Button>
            </>
          ) : null
        }
      </SuccessContent>

    </>
  )
}