import styled from "styled-components";

export const HistoryContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette[props.brand].secondary};
  padding-bottom: 48px;
`;
export const HistoryContentContainer = styled.div.attrs({
})`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }

`;
export const HistoryTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  text-align: left;
  color: ${props => props.theme.palette[props.brand].contrastTitle};
  margin-top: 40px;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
`;

export const HistoryImg = styled.img.attrs({
})`
`;

export const HistoryContent = styled.div.attrs({
})`
  margin-top: 40px;
  width: 191px;

`;

export const HistoryYear = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 40px;
  font-weight: 400;
  line-height: 96px;
  text-align: left;
  color: ${props => props.theme.palette[props.brand].contrastText};
`;

export const FormBorder = styled.div.attrs({
})`
  border: 2px solid ${props => props.theme.palette[props.brand].contrastText};
  width: 54px;
  margin-bottom: 24px;
`;

export const HistoryText = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: ${props => props.theme.palette[props.brand].contrastText};
`;