import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 


`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 40px;
`;

export const RegisterForgot = styled.div.attrs({
})`           
    margin-top: 32px;
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;

export const ForgotLink = styled.div.attrs({
})`           
    cursor: pointer;
    font-family: MetronicPro;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding: 16px;
    &:hover{
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;
export const FormContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    padding: 40px 40px 40px;
    width: 436px;
    margin-top: 40px;
    @media(max-width: 767px){
        padding: 40px 24px 40px;
        width: 100%;
    }
`;

export const LoginContent = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(max-width: 767px){
        padding: 0 24px 0;
        width: 100%;
    }
`;