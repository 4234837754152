import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { ReadObject } from "services/storage";
import { ThemedComponent } from "ui/theme";

import {
    SideBackgroundImageContainer,

    FormContent,
    AppLogo,
    Content,
    Touch,
    ColContainer
} from './styled'
import { Icon } from "ui/styled";
import useWindowSize from "utils/useWindowSize";


export default function ContainerUnauthenticated({ children, login, forgot }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const widthSize = useWindowSize()

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.jwt) {
            completeNext()
        }
    }

    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <ColContainer reverse={forgot}>
                        <Col md={{ size: login ? 12 : 6 }} className="nopaddings">
                            <FormContent login={login}>
                                {children}
                            </FormContent>
                        </Col>
                        {
                            !login && widthSize.width > 767 &&
                            <Col md={{ size: 6 }} className="nopaddings">
                                <SideBackgroundImageContainer forgot={forgot}>
                                    <Icon src="/icons/logo-white.svg" />
                                </SideBackgroundImageContainer>
                            </Col>
                        }
                    </ColContainer>
                </Content>
            </ThemedComponent>
        </>
    );
}