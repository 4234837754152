import styled from 'styled-components'

export const FormContent = styled.div.attrs({
})` 
    max-width: 1512px;  
    padding: 32px 96px;
    margin: 0 auto;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

export const ProductContent = styled.div.attrs({
})` 
    display: flex;
    margin-top: 24px;
    gap: 24px;
    justify-content: center;
    ${props => props.theme.breakpoints.down('lg')} {
        flex-direction: column;
        align-items: center;
    }
`;

export const ProductDetailsContent = styled.div.attrs({
})`    
    background-color: ${props => props.theme.palette.gray.gray40};
`; 
