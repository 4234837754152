import React from 'react';
import {
  ProductDescriptionContent,

  ProductContent,
  Description,
  Specifications,
  SpecificationItem,
  SpecificationLabel,
  SpecificationValue,
  TextDescription
} from './styled';
import { FormContent, Title } from 'ui/styled';

const productDetails = {
  description: "O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias. O texto foi popularizado na década de 60 com a disponibilização das folhas de Letraset contendo passagens de Lorem Ipsum, e mais recentemente com software de editoração eletrônica como o Aldus PageMaker incluindo versões do Lorem Ipsum.",
  specifications: [
    // { label: 'Origem', value: 'Estados Unidos' },
    // { label: 'Categoria: ', value: 'Standard' },
    // { label: 'Volume: ', value: '1000 ml' },
    // { label: 'Sabor:', value: 'Levemente encorpado, com toque de caramelo e baunilha.' },
    // { label: 'Aroma', value: 'Baunilha, carvalho com toque de especiarias.' },
    // { label: 'Envelhecido', value: '4 anos' },
    // { label: 'Cor', value: 'Palha dourada' },
    // { label: 'Principais ingredientes:', value: 'Milho mais suave e saboroso.' },
  ]
};

export default function ProductDescription({ product }) {
  return (
    <ProductDescriptionContent>
      <FormContent>
        <ProductContent>
          <Description>
            <Title>Descrição do Produto</Title>
            <TextDescription dangerouslySetInnerHTML={{ __html: product?.summary?.__text }} />
            {/* {productDetails.description.split('\n').map((paragraph, index) => (
              <TextDescription key={index}>{paragraph}</TextDescription>
            ))} */}
          </Description>
          {
            !productDetails?.specifications?.length ? null :
              <Specifications>
                <Title>Especificações do Produto</Title>
                {productDetails.specifications.map((spec, index) => (
                  <SpecificationItem key={index}>
                    <SpecificationLabel>{spec.label}:</SpecificationLabel>
                    <SpecificationValue>{spec.value}</SpecificationValue>
                  </SpecificationItem>
                ))}
              </Specifications>
          }
        </ProductContent>
      </FormContent>
    </ProductDescriptionContent>
  );
}