import styled from 'styled-components';

export const SellerContainer = styled.div.attrs({
})`
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
  background-color: ${props => props.theme.palette.colors.white};
`;

export const SellerPriceContainer = styled.div.attrs({
})`
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
 
`;

export const SellerNameContent = styled.div.attrs({
})`
  margin-bottom: 8px;
  background-color: ${props => props.theme.palette.sellers.main};
  padding: 0px 16px;
`;

export const Price = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 26px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

`;

export const Discount = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  background-color: ${props => props.theme.palette.red.main};
  padding: 6px 8px;
  color: ${props => props.theme.palette.colors.white};
  margin-left: 8px;

`;
export const DiscountContent = styled.div.attrs({
})`
  display: flex;
  align-items: center;
`;

export const OriginalPrice = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-decoration: line-through;
  color: ${props => props.theme.palette.colors.lightgrey};

`;

export const ShippingMethodContainer = styled.div.attrs({
})`
  padding: 12px 12px 12px 12px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
`;

export const PaymentConditions = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
  margin-bottom: 8px;
  margin-top: 4px;
`;

export const ShippingMethod = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;

`;

export const ShippingDetails = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 12px;

`;  