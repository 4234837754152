import styled from 'styled-components';

export const SummaryContainer = styled.div.attrs({
})`

  padding: ${props => props.orderDetails ? '0 16px' : '16px'};
  flex: 1;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  max-height: ${props => props.orderDetails ? '100%' : '396px'};
  min-width: 270px;
`;

export const SummaryTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 48px;
  text-align: left;

  border-bottom: 1px solid ${props => props.theme.palette.gray.gray300};
`;

export const SummaryItem = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${props => props.orderDetails ? props.theme.palette.colors.grey : props.theme.palette.gray.gray300};
  flex-wrap: wrap;
`;

export const SummaryLabel = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  padding: 8px 0;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
`;

export const SummaryValue = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding: 8px 0;
  color: ${props => props.theme.palette.colors.grey};
`;

export const SummaryTotal = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 4px 0;
  font-weight: bold;
  flex-wrap: wrap;
`;

export const TotalLabel = styled.div.attrs({
})`

  font-size: 24px;
  padding: 4px 0;
  font-weight: 500;
  text-align: left;

`;

export const TotalValue = styled.div.attrs({
})`

  font-size: 24px;
  font-weight: 700;
  text-align: left;
`;
