import styled from 'styled-components'

export const SideContainer = styled.div.attrs({
})`
  padding: 16px;
  width: 320px;
  height: 544px;
  background-color: ${props => props.theme.palette.white.main};
  z-index: 999;
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    }

`;
export const IconContent = styled.div.attrs({
})`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `;

export const Overlay = styled.div.attrs({
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  // Semi-transparent overlay
  z-index: 998;
  display: none;

  @media (max-width: 600px) {
    display: ${props => (props.active ? 'block' : 'none')}; // Show overlay when active
  }
`;
export const MenuList = styled.ul.attrs({
})`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const MenuItem = styled.li.attrs({
})`
    padding: 16px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${props => props.active ? `${props.theme.palette.colors.black}` : `${props.theme.palette.colors.grey}`};
  &:hover {
    color: ${props => props.theme.palette.colors.black};
  }
`;