import styled from "styled-components";

export const AddressContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  max-width:${props => props.addressDetails ? '424px' : '632px'} ;
  flex: 1;
  padding: 0 16px 16px;
 
`


export const AddressContent = styled.div.attrs({
})`
  display: flex;
  padding-bottom: 16px;
  margin-top: ${props => props.noMargin ? '0' : '16px'};

`
export const AddressInfoContent = styled.div.attrs({
})`
  width: 100%;
`
export const AddressItems = styled.div.attrs({
})`
  font-size: ${props => props.title ? '24px' : '18px'};
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

  ${props => props.noBorder ? '' : ` 
     border-bottom: 1px solid ${props.theme.palette.gray.gray100};
`}
  ${props => props.borderDetails ? `
    border-bottom: 1px solid ${props.theme.palette.colors.grey};` : ``}
${props => props.addressDetails ? `
  color: ${props.theme.palette.primary.main};
  ` : ``}


  padding-bottom: 8px;
  margin-top: 5px;
`

export const AddressItemsTitle = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

`