import React from 'react'
import { DetailsTitle, MeContent } from 'ui/styled'
import FormCore from "../Form/Core";
import { ButtonContainer } from './styled';
import Button from 'components/Form/Button';

export default function MyData() {
  const formItems = [
    {
      ref: 'nomeCompleto',
      type: 'text',
      placeholder: 'Nome completo',
      full: true,
    },
    {
      ref: 'cpf',
      type: 'text',
      placeholder: 'CPF',
      full: true,
    },
    {
      ref: 'email',
      type: 'text',
      placeholder: 'Email',
      half: true,
    },
    {
      ref: 'celular',
      type: 'text',
      placeholder: 'DDD + Celular',
      half: true,
    }
  ];
  return (
    <>
      <MeContent>
        <DetailsTitle>Meus dados</DetailsTitle>
        <FormCore formItems={formItems} />
        <ButtonContainer>
          <Button noRadius info>Cancelar</Button>
          <Button primary noRadius>Editar</Button>
        </ButtonContainer>
      </MeContent>

    </>
  )
}
