import styled from 'styled-components';

export const CartProductListContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  padding: 8px 16px 16px 16px;
  margin-top: 8px;

`;

export const Table = styled.div.attrs({
})`
    width: 800px;
    @media (max-width: 768px) {
        width: 100%;

    }
`;

export const ProductContent = styled.div.attrs({
})`
`;

export const ProductContainer = styled.div.attrs({
})`
  display: flex;
  gap: 2px;
`;

export const TableHeadCell = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-bottom: 4px;
 
`;

export const TableCell = styled.td.attrs({
})`
  ${props => props.flex && 'display: flex;'}
  max-width: 487px;
  gap: 2px;
`;
export const CartProductContent = styled.div.attrs({
})`
  display: flex;
  gap: 2px;
  align-items: flex-end;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }

`;
export const ProductTitle = styled.span.attrs({
})`
    cursor: pointer;
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    font-family: DM Serif Display;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: ${props => props.service ? '485.27px' : '398px'};
    display: flex;
    justify-content: space-between;
    padding: 14px 8px;
    flex-direction: column;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 8px;
    }
`;
export const SellerContent = styled.div.attrs({
})`
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        gap: 0;
        margin-top: 8px;
    } 
`;
export const ProductText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: ${props => props.bold ? '600' : '400'};
    ${props => props.left ? 'margin-left: 2px' : ''};
    line-height: 20px;
    text-align: left;
    color: ${props => props.theme.palette.colors.grey};
    margin-top: 4px;
`;

export const ProductImg = styled.img.attrs({
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const ProductImgContainer = styled.div.attrs({
})`
    cursor: pointer;
  width: 87.27px;
  height: 96px;
  padding: 5px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  @media (max-width: 768px) {
    height: 132px;
  }
  `;

export const ProductQuantity = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey}; 
  height: 96px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductPrice = styled.div.attrs({
})`

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;

  ${props => props.descont ? `
    text-decoration-line: line-through;
    color: ${props.theme.palette.gray.gray300};
    ` : ''};
    `;

export const ProductPriceContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey}; 
  display: flex;  
  justify-content: center;
  flex-direction: column;
  height: 96px;
  padding: 0 8px;
  width: 120px;
  @media (max-width: 768px) {
    width: 148px;
  }
`;

export const ProductDelete = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  height: 96px;
  display: flex;
  width: 39px;
  align-items: center;
  justify-content: center;
`;

export const FreightContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  margin-top: 16px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  width: 741px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FreightTitle = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`;

export const FreightText = styled.div.attrs({
})`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  display: flex;
  gap: 8px;
`;

export const FreightMethod = styled.span.attrs({
})`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${props => props.theme.palette.colors.grey};
`;