import styled from "styled-components";

export const DrinksCarouselContent = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
`;


export const DrinksCarouselTitle = styled.div.attrs({
})`
  font-family: DM Serif Display;
  font-size: 40px;
  font-weight: 400;
  line-height: 72px;
  text-align: left;
  margin-bottom: 16px;
`;
export const FormSpace = styled.div.attrs({
})`
  padding-bottom: 24px;
`;
