import React from 'react'
import { FormContent, Method, MethodsContent, PaymentMethodsContainer } from './styled'
import { Title } from 'ui/styled'

export default function PaymentMethods() {
  const methods = [
    '/icons/visa.svg',
    '/icons/master.svg',
    '/icons/diners.svg',
    '/icons/elo.svg',
    '/icons/amex.svg',
    '/icons/hiper.svg',
    '/icons/boleto.svg',
    '/icons/itau.svg',
    '/icons/bradesco.svg',
    '/icons/brasil.svg',
    '/icons/santander.svg',
  ]
  return (
    <>
      <PaymentMethodsContainer>
        <FormContent>
          <Title white>Formas de pagamento</Title>
          <MethodsContent>

            {
              methods.map((method, index) => (
                <Method key={index} src={method} alt="Forma de pagamento" />
              ))
            }
          </MethodsContent>

        </FormContent>
      </PaymentMethodsContainer>
    </>

  )
}
