import styled from "styled-components";

export const ProductListContent = styled.div.attrs({
})` 
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
`;

export const FormContent = styled.div.attrs({
})` 

  max-width: 1512px;  
  margin: 0 auto;
  padding: 56px 120px;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;
export const TitleContent = styled.div.attrs({
})` 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 600px) {
  margin-bottom: 16px;
}
`;

export const ProductContent = styled.div.attrs({
})` 
  display: flex;
  gap: 24px;
  overflow-x: auto;
`;