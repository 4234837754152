import React from 'react'

import { FormContent, ImgContainer, BrandsContainer, Img, BrandsContent } from './styled'
import { Title } from 'ui/styled'



export default function PromotionCategory() {
  const brands = []
  let a = [
    { id: 1, src: '/images/bourbon-promotion.svg', alt: 'bourbon-promotion' },
    { id: 1, src: '/images/singlemalt-promotion.svg', alt: 'singlemalt-promotion' },
    { id: 1, src: '/images/gin-promotion.svg', alt: 'gin-promotion.svg' },
  ]
  return !brands?.length ? null : (
    <>
      <BrandsContent>
        <FormContent>
          <Title>Promoções por categoria</Title>
          <BrandsContainer>
            {brands.map(brand => (
              <ImgContainer key={brand.id}>
                <Img src={brand.src
                } alt={brand.alt} />
              </ImgContainer>
            ))}
          </BrandsContainer>
        </FormContent>
      </BrandsContent>
    </>
  )
}
