import styled from 'styled-components'

export const DashboardPage = styled.div.attrs({
})` 
    background: ${props => props.theme.palette.colors.backgroundgrey};
`;

export const DashboardBody = styled.div.attrs({
})`       
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    background: ${props => props.theme.palette.colors.backgroundgrey};
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
`;

export const DashboardBodyContent = styled.div.attrs({
})`            
    background: ${props => props.theme.palette.colors.backgroundgrey};
    width: 100%;
    border-radius: 11px;
    min-height: calc(100vh - 108px);
`;

export const Content = styled.div.attrs({
})`           
    background: ${props => props.theme.palette.colors.backgroundgrey};
`;

export const HeaderContainer = styled.div.attrs({
})`           
   
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    height: 80px;
`;