import { GET, POST, PUT, DELETE } from './api'

export const Read = async () => {
    return await GET(`/home?populate=*`);
}

export const Update = async (params, id) => {
    return await PUT(`/home/${id}`, params);
}

export const Delete = async (id) => {
    return await DELETE(`/home/${ id }`);
} 
 