import styled from "styled-components";

export const PaymentContainer = styled.div.attrs({

})`
  background-color: ${props => props.theme.palette.colors.white};
  max-width: 424px;
  flex: 1;
  padding: 0 16px 16px;
`
export const PaymentText = styled.div.attrs({
})`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding-top: 8px;
  margin-bottom: 24px;
`