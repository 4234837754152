import styled from 'styled-components';

export const BreadcrumbContainer = styled.nav.attrs({
})`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: inherit;
  }

  a:hover {
    text-decoration: underline;
  }

  span {
    color: ${props => props.theme.palette.colors.black};
  }
`;

export const BreadcrumbItem = styled.div.attrs({
})`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: '';
    margin: 0 8px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('/icons/chevron-right.svg') no-repeat center;
    background-size: contain;
  }
`;