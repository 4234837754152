import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimerContainer, TimerDisplay, TimerLabel } from './styled';

const CountdownTimer = ({ maxTime }) => {
  const [time, setTime] = useState(() => {
    const savedTime = localStorage.getItem('countdownTime');
    return savedTime ? parseInt(savedTime, 10) : maxTime * 60;
  });

  useEffect(() => {
    localStorage.setItem('countdownTime', time);

    if (time <= 0) {
      localStorage.removeItem('countdownTime');
      return;
    }

    const intervalId = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);

  const formatTime = (time) => {
    const getMinutes = `0${Math.floor(time / 60)}`.slice(-2);
    const getSeconds = `0${time % 60}`.slice(-2);

    return `${getMinutes}:${getSeconds}`;
  };

  return (
    <TimerContainer>
      <TimerLabel>Tempo restante para pagar:</TimerLabel>
      <TimerDisplay>{formatTime(time)}</TimerDisplay>
    </TimerContainer>
  );
};

CountdownTimer.propTypes = {
  maxTime: PropTypes.number.isRequired,
};

export default CountdownTimer;