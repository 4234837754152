import React, { useState } from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

import {
    MaterialInput,
    StyledIconButton,
    StyledInputLabel
} from "./styled";


import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { ThemedComponent } from "ui/theme";
import { Search } from "@mui/icons-material";
import { Content } from "ui/styled";

export const Input = (props) => {
    const [visible, setVisible] = useState(false)

    const handleClickShowPassword = () => {
        setVisible(!visible);
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleClearInput = () => {
        if (props.onChange) {
            props.onChange({ target: { value: '' } });
        }
    };


    return (
        <>
            <ThemedComponent>
                {/* standard | outlined | filled */}
                <Content width={props.width} border={props.border}>
                    {!props.holder && <StyledInputLabel htmlFor={props.id}
                        top={props.top}>{props.placeholder}</StyledInputLabel>}
                    <FormControl fullWidth variant={"standard"}>
                        <MaterialInput
                            as={props.type === 'textarea' && 'textarea'}
                            width={props.width}
                            noRadius={props.noRadius}
                            lightgrey={props.lightgrey}
                            grey={props.grey}
                            white={props.white}
                            disableUnderline
                            placeholder={props.holder && props.placeholder}
                            id={props.id}
                            type={props.type === 'password' ? visible ? 'text' : 'password' : props.type}
                            value={props.value}
                            onChange={props.onChange}
                            onKeyDown={ev => typeof props.onSubmitEditing === 'function' ? (ev.keyCode === 13 ? props.onSubmitEditing() : null) : props.onKeyDown}
                            disabled={props.disabled}
                            height={props.type === 'textarea' ? '160px' : props.height}
                            color={props.secondary ? 'secondary' : 'primary'}
                            startAdornment={props.search && (
                                <InputAdornment position="start">
                                    <StyledIconButton
                                        aria-label="clear input"
                                        onClick={handleClearInput}
                                        onMouseDown={handleMouseDown}
                                        edge="start"
                                        grey
                                    >
                                        <Search />
                                    </StyledIconButton>

                                </InputAdornment>
                            )}
                            endAdornment={
                                <InputAdornment position="end" >
                                    {props.type === 'text' && !props.noClear && (
                                        <StyledIconButton
                                            aria-label="clear input"
                                            onClick={handleClearInput}
                                            onMouseDown={handleMouseDown}
                                            edge="end"
                                        >
                                            <CloseIcon />
                                        </StyledIconButton>
                                    )}

                                    {props.type === 'password' &&
                                        <StyledIconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDown}
                                            edge="end"
                                        >
                                            {visible ? <VisibilityOff /> : <Visibility />}
                                        </StyledIconButton>
                                    }
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Content>
            </ThemedComponent>
        </>
    );
}

export const MaskedInput = (props) => (
    <InputMask mask={props.mask} value={props.value} disabled={false} placeholder={props.placeholder} onChange={props.onChange} maskChar="">
        {(inputProps) => <Input {...inputProps} type="tel" value={null} onChange={null} />}
    </InputMask>
);

MaskedInput.propTypes = {
    type: PropTypes.oneOf(['password', 'text']),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
    onSubmitEditing: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    mask: PropTypes.string.isRequired
};


MaskedInput.defaultProps = {
    type: 'text',
    label: '',
    placeholder: '',
    value: '',
    secondary: false,
    onSubmitEditing: undefined,
    onChange: undefined,
    mask: ''
};

Input.propTypes = {
    type: PropTypes.oneOf(['password', 'text']),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    onSubmitEditing: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};


Input.defaultProps = {
    type: 'text',
    label: '',
    placeholder: '',
    value: '',
    secondary: false,
    disabled: false,
    onSubmitEditing: undefined,
    onChange: undefined,
};

export default Input; 
