import styled from 'styled-components';

export const CartProductListContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.white};
  margin-top: 8px;
`;

export const TableBody = styled.div.attrs({
})`
  display: flex;
  gap: 2px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TableCell = styled.div.attrs({
})`
  display: flex;
  gap: 2px;
`;
export const TableProduct = styled.div.attrs({
})`
  display: flex;
  gap: 2px;
  width: 100%;
`;
export const ProductTitle = styled.span.attrs({
})`
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    height: 96px;
    line-height: 24px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;

`;

export const ProductImg = styled.img.attrs({
})`
  width: 100%;
  height: 100%;
`;
export const ProductImgContainer = styled.div.attrs({
})`
  width: 87.27px;
  height: 96px;
  padding: 5px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  
  `;

export const ProductQuantity = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey}; 
  height: 96px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const QuantityNumber = styled.span.attrs({
})`
  width: 58px;
  height: 46px;
  background-color: ${props => props.theme.palette.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ProductPrice = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  ${props => props.descont ? `
    text-decoration-line: line-through;
    color: ${props.theme.palette.gray.gray300};
    ` : ''};
    `;

export const ProductPriceContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey}; 
  display: flex;  
  justify-content: center;
  flex-direction: column;
  height: 96px;
  padding: 0 8px;
  width: 115px;
`;

export const ProductDelete = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  height: 96px;
  display: flex;
  width: 39px;
  align-items: center;
  justify-content: center;
`;

