import React from 'react';
import { CheckContainer, MethodsTitle, PaymentContent, PaymentBody, InfoMessage, List, ListItem } from './styled';
import Check from 'components/Form/Check';
import { installmentOptions } from 'utils/options';
import FormCore from "../Form/Core";
import { Content } from 'ui/styled';

export default function Payment({ method, isSelected, onSelect }) {
  const formItems = [
    {
      ref: 'numeroCartao',
      type: 'text',
      placeholder: 'Número do cartão',
      border: true,
      full: true,
    },
    {
      ref: 'validade',
      type: 'text',
      placeholder: 'Data de validade',
      half: true,
    },
    {
      ref: 'cvv',
      type: 'text',
      placeholder: 'CVV',
      half: true,
    },
    {
      ref: 'nomeCompleto',
      type: 'text',
      placeholder: 'Nome completo',
      full: true,
    },
    {
      ref: 'parcelamento',
      type: 'select',
      placeholder: 'Parcelamento',
      options: installmentOptions,
      full: true,
    },
  ];
  const instructions = [
    "Clique em 'Finalizar Pedido';",
    "Na tela seguinte copie o código;",
    "No App do seu banco, selecione Pagar com o Pix e cole o código que você copiou;",
    "Confira os dados e confirme o pagamento;",
    "Pronto, o pagamento foi feito com sucesso!"
  ];

  return (
    <>
      <PaymentContent>
        <Check
          onChange={onSelect}
          alignTop
          success
          checked={isSelected}
          label={<MethodsTitle>{`Pagar com ${method}`}</MethodsTitle>}
        />

        <PaymentBody>
          {
            method === 'cartão de crédito' ? (
              <>
                <FormCore formItems={formItems} />
                <CheckContainer>
                  <Check
                    label="Salvar cartão para próxima compra"
                    success
                  />
                </CheckContainer>
              </>
            ) : null
          }
          {
            method === 'pix' ? (
              <List>
                {instructions.map((instruction, index) => (
                  <ListItem key={index}>{instruction}</ListItem>
                ))}
              </List>
            ) : null
          }
          {
            method === 'boleto' ? (
              <>
                <InfoMessage border>
                  Clique no botão "Finalizar Pedido" para confirmar seu pedido e imprimir seu boleto.
                  Imprima o boleto e pague na agência bancária de sua preferência ou através dos serviços de Internet Banking.
                </InfoMessage>
                <InfoMessage>
                  Acompanhe o prazo de validade do seu boleto, ele não será enviado pelos Correios.
                </InfoMessage>
              </>
            ) : null
          }
        </PaymentBody>
      </PaymentContent>
    </>
  )
}