import styled from 'styled-components'

export const PriceContent = styled.div.attrs({

})` 
  margin-top: 16px;
  border-top: 1px solid ${props => props.theme.palette.gray.gray100};
  border-bottom: 1px solid ${props => props.theme.palette.gray.gray100};
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Price = styled.div.attrs({
})` 
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
`;
export const OriginalPrice = styled.div.attrs({
})` 
  font-family: DM Serif Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-decoration-line: line-through;
  color: ${props => props.theme.palette.gray.gray300};
`;
export const DiscountedPrice = styled.div.attrs({
})` 
  font-family: DM Serif Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

`;
export const PaymentDetails = styled.div.attrs({
})` 
  styleName: XXS-12px/Regular;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
  flex-wrap: wrap;
`;


export const DiscountContent = styled.div.attrs({
})` 
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;
export const PaymentConditions = styled.div.attrs({
})` 
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;

  margin-bottom: 8px;


`;