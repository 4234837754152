import React from 'react'
import { StatusContainer, StatusText, StatusTextContent } from './styled'
import { Content, DetailsTitle } from 'ui/styled'
import Button from 'components/Form/Button'

export default function OrderStatus({ status }) {
  return (
    <>
      <StatusContainer>
        <DetailsTitle>Status: {status.status}</DetailsTitle>
        <StatusTextContent>
          <Content>
            <StatusText>Data do pedido:  {status.orderDate}</StatusText>
            <StatusText>Número do pedido: {status.orderNumber}</StatusText>
          </Content>
          <Button info width={'225px'} onClick={() => console.log('Clicou')}>Acessar meu boleto</Button>
        </StatusTextContent>

      </StatusContainer>
    </>

  )
}
