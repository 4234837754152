import React, { useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';

import ContainerAuthenticated from "containers/Authenticated";

import CheckoutContent from "components/CheckoutContent";
import { Title, CheckoutContainer, CheckoutForm, CheckContent, Label, AddressContainer } from "./styled";
import Address from "components/Address";
import Payment from "components/Payment";
import PurchaseSummary from "components/PurchaseSummary";
import PaymentSuccess from "components/PaymentSuccess";
import Check from "components/Form/Check";
import AddressForm from "components/AddressForm";


//replace with real values
const servicesTotal = 234.56;
const grandTotal = 1469.12;
const productsTotal = 1234.56;
const qtd = 4;

export default function Checkout() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const [selectedAddressId, setSelectedAddressId] = useState(1);
    const [step, setStep] = useState(0);
    const [newAddress, setNewAddress] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const steps = useMemo(() => [
        {
            pageTitle: 'Endereço',
            title: 'Selecione um endereço de envio',
            label: 'Endereço',
        },
        {
            pageTitle: 'Pagamento',
            title: 'Selecione a forma de pagamento',
            label: 'Pagamento',
        },
        {
            label: 'Finializado',
        }

    ], []);
    const next = () => {
        if (step === steps.length - 1) {
            //save data
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            return;
        }
        setStep(step - 1);
    }
    const addresses = [
        {
            id: 1,
            label: 'Casa',
            name: 'José Santos da Silva',
            CEP: '01234-567',
            street: 'Rua dos Mares',
            number: '123456',
            city: 'Parque do Litoral',
            state: 'São Paulo/SP',
            complements: 'Bloco 1 - Escada 2 - Apartamento 123'
        },
        {
            id: 2,
            label: 'Trabalho',
            name: 'José Santos da Silva',
            CEP: '01234-567',
            street: 'Avenida Paulista',
            number: '987',
            city: 'Centro',
            state: 'São Paulo/SP',
            complements: 'Sala 456'
        }
    ];

    const handleSelect = (id) => {
        setSelectedAddressId(id);
    };
    const paymentMethods = [
        'cartão de crédito',
        'pix',
        'boleto'
    ];
    const handlePaymentSelect = (method) => {
        setPaymentMethod(method);
    };

    return (
        <>
            {newAddress ? <AddressContainer>
                <AddressForm setNewAddress={setNewAddress} />
            </AddressContainer> : null}
            <ContainerAuthenticated>
                <CheckoutContent title={steps?.[step]?.pageTitle} tabType={steps?.[step]?.label} PaymentSuccess={step === 2}>
                    {
                        step === 2 ? (
                            <PaymentSuccess method={paymentMethod} orderNumber={123213213} />
                        ) :
                            <CheckoutForm>
                                <CheckoutContainer address={step === 0}>
                                    {!(step === 0) ? null :
                                        <>
                                            <Title>{steps?.[step]?.title}</Title>
                                            {
                                                addresses.map((address, index) => (
                                                    <Address
                                                        noMargin={index === 0}
                                                        key={address.id}
                                                        address={address}
                                                        isSelected={address.id === selectedAddressId}
                                                        onSelect={() => handleSelect(address.id)}
                                                        next={next}
                                                    />
                                                ))
                                            }
                                            <CheckContent>
                                                <Check
                                                    checked={newAddress}
                                                    label={
                                                        <Label>Adicionar novo endereço</Label>
                                                    }
                                                    onChange={() => setNewAddress(!newAddress)}
                                                />
                                            </CheckContent>
                                        </>
                                    }
                                    {
                                        step === 1 ? <>
                                            <Title payment>{steps?.[step]?.title}</Title>
                                            {
                                                paymentMethods.map((method, index) => (
                                                    <Payment
                                                        key={index}
                                                        method={method}
                                                        isSelected={paymentMethod === method}
                                                        onSelect={() => handlePaymentSelect(method)}
                                                    />
                                                ))
                                            }
                                        </> : null
                                    }
                                </CheckoutContainer>
                                {
                                    step === 1 ? (
                                        <PurchaseSummary
                                            productsTotal={productsTotal}
                                            servicesTotal={servicesTotal}
                                            grandTotal={grandTotal}
                                            qtd={qtd}
                                            onCheckout={() => next()}
                                            onContinueShopping={() => { }} />
                                    ) : null
                                }
                            </CheckoutForm>
                    }
                </CheckoutContent>

            </ContainerAuthenticated >
        </>
    );
}