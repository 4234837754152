import styled from "styled-components";

export const BannerContent = styled.div.attrs({

})`
    padding:  120px 0px;
    background: ${props => props.theme.palette.colors.white};

 
`;

export const BannerImage = styled.div.attrs({
})`
    width: 100%;
    height: 440px;
    object-fit: cover;
    background: black url(${props => props.url}) no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

`;

export const BannerProduct = styled.img.attrs({
})`
  max-width: 494.96px;
  max-height: 682.83px;
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;
export const Content = styled.div.attrs({
})`
   max-width: 1512px;
   margin: 0 auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    position: relative;
  }

`;

export const BannerTitle = styled.div.attrs({
})`
  max-width: 640px;
  font-family: DM Serif Display;
  font-size: 44px;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
  color: ${props => props.theme.palette.colors.white};
  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
    padding: 0 16px;
  }

`;