import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'
 
export const CoreContext = createContext({})

export const CoreState = ({ children }) => {
      
	const [ user, setUser ] = useState( ReadObject('user') ? ReadObject('user') : [])  
	const [ cart, setCart ] = useState( ReadObject('cart') ? ReadObject('cart') : [])  
	const [ agree, setAgree ] = useState( ReadObject('agree') ? ReadObject('agree') : false)  

	const contextValue = {  
		user, setUser,
		agree, setAgree,
		cart, setCart
	}

	// to persist state when app reload  
    useEffect(() => { SaveObject('user', user) ;}, [user]) 
    useEffect(() => { SaveObject('cart', cart) ;}, [cart]) 
    useEffect(() => { SaveObject('agree', agree) ;}, [agree]) 

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
