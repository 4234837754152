import React, { useEffect, useMemo, useState } from "react";
import {
    BodyContent,
    ButtonLink,
    FormContent,
    ProductContent,
    ProductQuantity,
    TitleContent
} from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import Footer from "components/Footer";
import { Content, Load, LoadCenter, Title } from "ui/styled";
import Select from "components/Form/Select";
import SideBar from "components/SideBar";
import ProductCard from "components/ProductCard";
import { sortOptions } from "utils/options";
import { drinks } from "utils/drinks";
import PaymentMethods from "components/PaymentMethods";
import Brands from "components/Brands";
import useWindowSize from "utils/useWindowSize";
import { Read } from "services/products";
import { normalizeStrapiList } from "utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function About() {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    
    const contactEmail = "contato@spiritsclub.com.br"

    return (
        <>
            <ContainerAuthenticated>
                <FormContent>


                    { ( loading ) ? <LoadCenter> <Load black /> </LoadCenter> : <></> }
                    
                    <TitleContent>
                        <Content>
                            <Title noSpace>Sobre nós</Title>
                        </Content>
                    </TitleContent>

                    <h2>Quem Somos</h2>
                    <p>No Prime Spirits Club, nossa missão é trazer o melhor das bebidas importadas para você. Fundada em [Ano], somos uma equipe apaixonada por bebidas finas e dedicados a oferecer uma experiência de compra excepcional.</p>
                    <p>Com uma vasta seleção de destilados, vinhos e licores de todo o mundo, nosso objetivo é proporcionar aos nossos clientes acesso a produtos de alta qualidade que não estão disponíveis em qualquer lugar. Trabalhamos diretamente com produtores e distribuidores para garantir a autenticidade e a excelência de cada produto que oferecemos.</p>

                    <h2>Nossa Missão</h2>
                    <p>Nosso compromisso é proporcionar uma experiência de compra única e personalizada, com um atendimento ao cliente de primeira linha. Queremos que cada visita ao Prime Spirits Club seja uma jornada de descoberta e prazer.</p>
                    <p>Estamos sempre em busca de novas e emocionantes opções para adicionar ao nosso portfólio e garantir que nossos clientes tenham acesso às melhores bebidas importadas do mercado.</p>

                    <h2>Nossos Valores</h2>
                    <p><strong>Qualidade:</strong> Selecionamos rigorosamente nossos produtos para garantir a mais alta qualidade.</p>
                    <p><strong>Transparência:</strong> Trabalhamos de forma transparente com nossos fornecedores e clientes.</p>
                    <p><strong>Inovação:</strong> Estamos sempre em busca de novas tendências e produtos para oferecer aos nossos clientes.</p>
                    <p><strong>Atendimento ao Cliente:</strong> Oferecemos um atendimento personalizado para garantir a satisfação total de nossos clientes.</p>


                    {/* <h2>Entre em Contato Conosco</h2>
                    <p>Se você tiver alguma dúvida ou quiser saber mais sobre nossos produtos e serviços, entre em contato conosco:</p>
                    <p>Email: <a href="mailto:email@exemplo.com">email@exemplo.com</a></p>
                    <p>Telefone: [número de telefone]</p>
                    <p>Endereço: [endereço da empresa]</p> */}


                </FormContent>
                {/* <Brands /> */}
                <PaymentMethods />
                <Footer />
            </ContainerAuthenticated>
        </>
    );
}