import React, { useEffect, useState } from 'react';
import { GalleryContainer, MainImage, MainImageContent, Thumbnail, Thumbnails } from './styled';

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [hoveredImage, setHoveredImage] = useState(null);

  const displayImage = hoveredImage || selectedImage;

  useEffect(() => {
    setSelectedImage(images[0])
  }, [images])

  return (
    <GalleryContainer>
      <Thumbnails>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            selected={selectedImage === image}
            onClick={() => setSelectedImage(image)}
            onMouseEnter={() => setSelectedImage(image)}
            onMouseLeave={() => setHoveredImage(null)}
          />
        ))}
      </Thumbnails>
      <MainImageContent>
        { !displayImage ? null : <MainImage src={displayImage} alt="Selected" /> }
      </MainImageContent>
    </GalleryContainer>
  );
};

export default ImageGallery;