import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BreadcrumbContainer, BreadcrumbItem } from './styled';


export default function Breadcrumb() {

  const location = useLocation();
  const { id } = useParams();
  const pathname = location.pathname;
  const pathnames = pathname.split('/').filter((x) => x);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const breadcrumbs = [
    { label: 'Home', path: '/' },
    ...pathnames.map((name, index) => {
      const path = `/${pathnames.slice(0, index + 1).join('/')}`;
      if (name === id) {
        const formattedName = name.replace(/-/g, ' ');
        return { label: formattedName, path };
      }
      return { label: capitalizeFirstLetter(name), path };
    })
  ];

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbItem key={index}>
          {index !== breadcrumbs.length - 1 ? (
            <Link to={item.path}>{item.label}</Link>
          ) : (
            <span>{item.label}</span>
          )}
        </BreadcrumbItem>
      ))}
    </BreadcrumbContainer>
  );
};

