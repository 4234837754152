import styled from 'styled-components';

export const VideoContainer = styled.div.attrs({

})`
  width: 1020px;
  height: 572px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

export const StyledVideo = styled.video.attrs({
})`
  width: 100%;
  height: 100%;
`;