import styled from 'styled-components';


export const StatusBarContainer = styled.div.attrs({
})`
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  padding: 16px;
  @media (max-width: 768px) {
    width: 100%;
  }

`;
export const StatusItems = styled.div.attrs({
})`
  display: flex;
  gap: 40px;
  justify-content: center;
  width: 100%;
  @media (max-width: 768px) {
    gap: 24px;
    flex-wrap: wrap;
  }
  `;

export const StatusItem = styled.div.attrs({
})`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${props => (props.active ? `${props.theme.palette.primary.main}` : `${props.theme.palette.gray.gray600}`)};
  border-bottom: 4px solid ${props => (props.active ? `${props.theme.palette.primary.main}` : 'transparent')};
  padding-bottom: 8px;

`;

export const StatusContent = styled.div.attrs({
})`
  padding: 16px;
  width: 100%;
  background-color: ${props => props.theme.palette.white.main};
  margin-top: 24px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;