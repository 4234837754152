import React from 'react'
import { Card, CardContainer, CardTitle, FormContent } from './styled'

export default function SuggestionsCard({ cardData }) {
  return !cardData?.length ? null : (
    <>
      <FormContent>
        <CardContainer>
          {cardData.map((card, index) => (
            <Card color={card.color} key={index}>
              <CardTitle>{card.title}</CardTitle>
            </Card>
          ))}
        </CardContainer>
      </FormContent>
    </>
  )
}
